import { PARTNERS_API_ENDPOINT } from "@/utility/apiRoutes";
import { useApiClient } from "../useApiClient";

export const usePartnerApi = () => {
  const apiClient = useApiClient();

  const getPartnersData = async () => apiClient(PARTNERS_API_ENDPOINT);

  return { getPartnersData };
};
