import React, { useState } from "react";
import ConfirmationModal from "../components/modals/ConfirmationModal.jsx";

const UseConfirmation = () => {
  const [show, setShow] = useState(false);

  const showConfirmation = () => setShow(true);

  const hideConfirmation = () => setShow(false);

  const renderConfirmation = ({
    title = "Confirm",
    content = "Are you sure?",
    primaryActionText = "Confirm",
    primaryAction = () => {},
    primaryActionIsDestructive = false,
    loading = false,
    onClose = () => {},
  }) => (
    <ConfirmationModal
      show={show}
      title={title}
      content={content}
      primaryAction={primaryAction}
      primaryActionText={primaryActionText}
      primaryActionIsDestructive={primaryActionIsDestructive}
      loading={loading}
      onClose={() => {
        hideConfirmation();
        onClose();
      }}
    />
  );

  return { renderConfirmation, showConfirmation, hideConfirmation };
};

export default UseConfirmation;
