import {
  ADDRESS_AUTOCOMPLETE_SUGGESTIONS_API_ENDPOINT,
  ADDRESS_DETAILS_API_ENDPOINT,
  INSTALLED_SEO_APPS_API_ENDPOINT,
  KEYWORD_METRICS_API_ENDPOINT,
  TRIGGER_EVENT_API_ENDPOINT,
} from "../../utility/apiRoutes";
import { useApiClient } from "../useApiClient";

export const useUtilityApi = () => {
  const apiClient = useApiClient();

  const getAddressAutocompleteSuggestions = async (inputText) => {
    try {
      const { suggestions } = await apiClient(
        `${ADDRESS_AUTOCOMPLETE_SUGGESTIONS_API_ENDPOINT}?inputText=${inputText}`
      );
      return suggestions;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error getting address autocomplete suggestions: ", err);
      return [];
    }
  };

  const getAddressDetailsByPlaceId = async (placeId) => {
    try {
      const { addressDetails } = await apiClient(`${ADDRESS_DETAILS_API_ENDPOINT}?placeId=${placeId}`);
      return addressDetails;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error getting address details for placeId: ", placeId, err);
      return null;
    }
  };

  const getKeywordMetrics = async (keyword) => {
    try {
      const { keywordMetrics } = await apiClient(`/${KEYWORD_METRICS_API_ENDPOINT}?keyword=${keyword}`);
      return keywordMetrics;
    } catch (err) {
      // processAndShowErrorNotification(err);
      return null;
    }
  };

  const getListOfInstalledSeoApps = async () => {
    try {
      const { installedApps } = await apiClient(INSTALLED_SEO_APPS_API_ENDPOINT);
      return installedApps;
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log(err);
      return [];
    }
  };

  /**
   * Trigger an event in server
   * @param {string} eventName
   * @param {import("../../jsDocTypes").NotificationReadEventDetails | import("../../jsDocTypes").AllNotificationReadEventDetails} eventDetails
   * @returns
   */
  const triggerEvent = async (eventName, eventDetails) => {
    try {
      const payload = { eventName, eventDetails };
      const response = await apiClient(TRIGGER_EVENT_API_ENDPOINT, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      console.log("trigger event response: ", eventName, eventDetails, response);
      return response;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return {
    triggerEvent,
    getKeywordMetrics,
    getListOfInstalledSeoApps,
    getAddressAutocompleteSuggestions,
    getAddressDetailsByPlaceId,
  };
};
