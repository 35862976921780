


export const Flex = ({children, alignItems = 'flex-start', justifyContent = 'flex-start', flexWrap = 'wrap', gap = '0'}) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: alignItems,
          justifyContent: justifyContent,
          flexWrap: flexWrap,
          gap: gap,
        }}
      >
        {children}
      </div>
    );
  };

export const Padding = ({children, padding = '0px'}) => {
    return (
      <div
        style={{
          padding: padding,
        }}
      >
        {children}
      </div>
    );
  }