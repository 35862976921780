import React from "react";

function Tutorial() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill="#0094D5"
      />
      <path
        d="M13.7509 13.7396H26.3117C26.543 13.7396 26.7684 13.7717 26.9871 13.825C26.7442 13.0596 26.0359 12.5 25.1909 12.5H14.8092C13.9592 12.5 13.2471 13.0667 13.0088 13.8392C13.2475 13.775 13.4963 13.7396 13.7509 13.7396Z"
        fill="white"
      />
      <path
        d="M12.4996 15.8332H27.4996C27.7504 15.8332 27.9921 15.8712 28.2204 15.9403C28.1842 15.8341 28.1421 15.7295 28.0888 15.6307C27.7342 14.9782 27.0533 14.5732 26.3113 14.5732H13.7504C13.0063 14.5732 12.3242 14.9803 11.9704 15.6357C11.9208 15.7278 11.8813 15.8245 11.8467 15.9232C12.055 15.8666 12.2733 15.8337 12.4996 15.8337V15.8332Z"
        fill="white"
      />
      <path
        d="M27.4997 16.6665H12.4997C11.5793 16.6665 10.833 17.4128 10.833 18.3332V26.2498C10.833 27.1703 11.5793 27.9165 12.4997 27.9165H27.4997C28.4201 27.9165 29.1663 27.1703 29.1663 26.2498V18.3332C29.1663 17.4128 28.4201 16.6665 27.4997 16.6665ZM22.8805 23.374L19.1305 25.539C18.9347 25.6519 18.7201 25.7086 18.5055 25.7086C18.2909 25.7086 18.0759 25.6523 17.8805 25.539C17.4893 25.3132 17.2555 24.9086 17.2555 24.4565V20.1265C17.2555 19.6744 17.4893 19.2698 17.8805 19.044C18.2718 18.8182 18.7393 18.8182 19.1305 19.044L22.8805 21.209C23.2718 21.4348 23.5055 21.8398 23.5055 22.2915C23.5055 22.7432 23.2718 23.1482 22.8805 23.374Z"
        fill="white"
      />
    </svg>
  );
}

export default Tutorial;
