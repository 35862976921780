import React from 'react';

const Icon1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60.035" height="60.034" viewBox="0 0 60.035 60.034">
    <path id="Rectangle_6" data-name="Rectangle 6" d="M0,0H45A12,12,0,0,1,57,12V39A18,18,0,0,1,39,57H11A11,11,0,0,1,0,46V0A0,0,0,0,1,0,0Z" transform="translate(3 3)" fill="#ffa53c" opacity="0.1"/>
    <path id="box_3_" data-name="box (3)"
          d="M60,46.978a15.819,15.819,0,1,1-11.3-18.135,2.345,2.345,0,0,1-1.261,4.517,11.141,11.141,0,1,0,7.95,12.767A2.345,2.345,0,1,1,60,46.978Zm-18.6-5.6a2.345,2.345,0,1,0-3.316,3.316l3.453,3.453a4.113,4.113,0,0,0,5.789.015L57.57,38.018a2.345,2.345,0,1,0-3.3-3.333l-9.833,9.732ZM60.034,7.037v7.035a2.345,2.345,0,0,1-2.345,2.345V25.8a2.345,2.345,0,1,1-4.69,0v-9.38H7.035V50.656a4.7,4.7,0,0,0,4.69,4.69H26.617a2.345,2.345,0,1,1,0,4.69H11.725a9.391,9.391,0,0,1-9.38-9.38V16.418A2.345,2.345,0,0,1,0,14.073V7.037A7.043,7.043,0,0,1,7.035,0H53A7.043,7.043,0,0,1,60.034,7.037ZM4.69,11.727H55.344V7.037A2.348,2.348,0,0,0,53,4.692H7.035A2.348,2.348,0,0,0,4.69,7.037ZM37.522,23.453a2.345,2.345,0,0,0-2.345-2.345H24.624a2.345,2.345,0,0,0,0,4.69H35.176A2.345,2.345,0,0,0,37.522,23.453Z"
          transform="translate(0 -0.002)" fill="#ffa53c"/>
  </svg>
);

export default Icon1;
