import React from 'react';

const Icon3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g id="Group_718" data-name="Group 718" transform="translate(-1554 -31)">
      <rect id="Rectangle_6" data-name="Rectangle 6" width="60" height="60" rx="20" transform="translate(1554 31)" fill="#fe504f" opacity="0.1"/>
      <g id="Layer_15" data-name="Layer 15" transform="translate(1552 29)">
        <path id="Path_37" data-name="Path 37"
              d="M52.1,2H11.921A9.921,9.921,0,0,0,2,11.921V52.1A9.921,9.921,0,0,0,11.921,62H52.1A9.921,9.921,0,0,0,62,52.1V11.921A9.921,9.921,0,0,0,52.1,2Zm5.614,50.1A5.636,5.636,0,0,1,52.1,57.714H11.921A5.636,5.636,0,0,1,6.286,52.1V11.921a5.636,5.636,0,0,1,5.636-5.636H52.1a5.636,5.636,0,0,1,5.614,5.636Z"
              transform="translate(0 0)" fill="#fe504f"/>
        <path id="Path_38" data-name="Path 38"
              d="M49.74,11.294a2.143,2.143,0,0,0-2.936.793L40.2,23.508l-4.029-3.257a2.118,2.118,0,0,0-3.15.536L26.79,30.708l-5.936-5.293a2.151,2.151,0,0,0-3.043.193L5.747,39.58a2.158,2.158,0,0,0,3.279,2.807L19.74,30.065l6.214,5.529a2.143,2.143,0,0,0,3.236-.471l6.3-10.05,4.093,3.3a2.143,2.143,0,0,0,3.193-.6L50.64,14.187A2.143,2.143,0,0,0,49.74,11.294Z"
              transform="translate(3.689 10.292)" fill="#fe504f"/>
      </g>
    </g>
  </svg>
);

export default Icon3;
