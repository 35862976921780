import { useApiClient } from "../useApiClient.js";
import { showNotification } from "../../utility/helpers.jsx";
import { useTranslation } from "react-i18next";

export const useOnboardApi = () => {
  const { t } = useTranslation();
  const apiClient = useApiClient();

  const getStoreInformationData = async () => {
    try {
      return await apiClient("/onboard/store-information");
    } catch (err) {
      console.error("getStoreInformationData", err);
    }
  };
  const getSEOSettingsData = async () => {
    try {
      return await apiClient("/onboard/seo-setting");
    } catch (err) {
      console.error("getSEOSettingsData", err);
    }
  };
  const getAnalysisData = async () => {
    try {
      return await apiClient("/onboard/analysis");
    } catch (err) {
      console.error("getAnalysisData", err);
    }
  };
  const getOptimizationData = async () => {
    try {
      return await apiClient("/onboard/optimization");
    } catch (err) {
      console.error("getOptimizationData", err);
    }
  };

  const submitStoreInformation = async (industry, setFormErrors) => {
    try {
      const { message } = await apiClient("/onboard/store-information", {
        method: "POST",
        body: JSON.stringify({ industry }),
      });
      showNotification({
        type: "success",
        message: t(message),
      });
    } catch (err) {
      // processAndShowErrorNotification(err, setFormErrors);
      throw new Error(err.message);
    }
  };

  const submitDataMigrationInfo = async (selectedApp) => {
    try {
      const { message } = await apiClient("/onboard/migrate-data", {
        method: "POST",
        body: JSON.stringify({ selectedApp }),
      });

      showNotification({ type: "success", message: t(message) });

      return true;
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error(err.message);
    }
  };

  const submitOptimizationAutoFix = async () => {
    try {
      const { message } = await apiClient("/onboard/optimization", {
        method: "POST",
        body: JSON.stringify({ isOnboard: true }),
      });

      showNotification({
        type: "success",
        message: t(message),
      });
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Failed!");
    }
  };
  const submitOnboardSuccess = async () => {
    try {
      const { message } = await apiClient("/onboard/success", {
        method: "POST",
        body: JSON.stringify({ complete: true }),
      });
      showNotification({
        type: "success",
        message: t(message),
      });
    } catch (err) {
      // processAndShowErrorNotification(err);
    }
  };

  const submitSeoSetting = async ({ templatePageTitle, templateMetaDescription, templateImgAltText }) => {
    try {
      const customTemplate = {
        templatePageTitle,
        templateMetaDescription,
        templateImgAltText,
      };

      const payload = {
        customTemplate: templatePageTitle || templateMetaDescription || templateImgAltText ? customTemplate : null,
      };

      const { message, settingsUpdated, shop } = await apiClient("/onboard/seo-setting", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      showNotification({ type: "success", message: t(message) });
      return { message, settingsUpdated, shop };
    } catch (err) {
      console.error("submitSeoSetting", err);
      // processAndShowErrorNotification(err);
    }
  };

  const checkThemeAppEmbedStatus = async () => {
    try {
      const { enabled, settings } = await apiClient("/theme-app-embed-status");
      // console.log("settings: ", settings);
      return enabled;
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  return {
    getStoreInformationData,
    submitStoreInformation,
    getSEOSettingsData,
    submitSeoSetting,
    getAnalysisData,
    getOptimizationData,
    submitOptimizationAutoFix,
    submitOnboardSuccess,
    submitDataMigrationInfo,
    checkThemeAppEmbedStatus,
  };
};
