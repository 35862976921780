import { useTranslation } from "react-i18next";
import { showNotification } from "../../utility/helpers";
import { useApiClient } from "../useApiClient";

export const useImageApi = () => {
  const { t } = useTranslation();
  const apiClient = useApiClient();

  const getImages = async (type, query) =>
    apiClient(`/images/${type}`, {
      query,
    });

  const optimizeImage = async (conf) => {
    const data = await apiClient("/images/optimize", {
      method: "POST",
      body: JSON.stringify(conf),
    });

    showNotification({ message: t("Image optimized") });

    return data;
  };

  const optimizeImagesViaQueue = async (conf) => {
    const data = await apiClient("/images/optimize/queue", {
      method: "POST",
      body: JSON.stringify(conf),
    });

    showNotification({ message: t("Image optimization started") });
    return data;
  };

  const saveOptimizedImages = async (images) => {
    const data = await apiClient("/images/optimize/save", {
      method: "PUT",
      body: JSON.stringify(images),
    });

    showNotification({ message: t("Image updated") });

    return data;
  };

  const restoreImage = async (conf) => {
    const data = await apiClient("/images/restore", {
      method: "POST",
      body: JSON.stringify(conf),
    });

    showNotification({ message: t("Image(s) restored") });

    return data;
  };

  const getSettings = async () =>
    apiClient("/images/settings", {
      method: "GET",
    });

  const updateSettings = async (conf) =>
    apiClient("/images/settings", {
      method: "POST",
      body: JSON.stringify(conf),
    });

  return {
    getImages,
    optimizeImagesViaQueue,
    optimizeImage,
    saveOptimizedImages,
    restoreImage,
    getSettings,
    updateSettings,
  };
};
