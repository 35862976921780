export const rootFlexStyle = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

export const collapseHeaderStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px",
  cursor: "pointer",
};
export const collapseHeaderTitleStyle = {
  fontSize: 16,
  fontWeight: 600,
};
export const collapsibleIconStyle = {
  fontSize: 20,
};

export const cardBodyPaddingStyle = {
  padding: 16,
};
export const flexBasisStyle = {
  flex: 1,
};

export const breadCrumbStyle = {
  display: "flex",
  gap: 20,
};

export const breadCrumbUlStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: "#152c5b",
  padding: 0,
  margin: "0 0 5px",
  listStyle: "none",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

export const breadCrumbListStyle = {
  color: "#5f6368",
  display: "flex",
  alignItems: "center",
};

export const previewTitleStyle = {
  fontSize: 20,
  fontWeight: 500,
  color: "#1a0dab",
  marginBottom: 3,
};

export const previewTextStyle = {
  fontSize: 14,
  fontWeight: 400,
  // color: "#8a95ad",
  lineHeight: 1.7,
  color: "#4d5156",
};

export const previewContentWrapperStyle = {
  display: "flex",
  justifyContent: "space-between",
  gap: 20,
};

export const previewThumbWrapperStyle = {
  flex: "0 0 92px",
  height: 92,
  width: 92,
  overflow: "hidden",
  borderRadius: 10,
};

export const facebookPriviewThumbStyle = {
  maxHeight: 350,
  fontSize: 0,
  overflow: "hidden",
};

export const facebookPreviewLinkStyle = {
  color: "#65676b",
  fontSize: 14,
  fontWeight: 400,
};

export const facebookPreviewtitleStyle = {
  fontSize: 16,
  fontWeight: 700,
  color: "#29294f",
  // font-family: "DM Sans",sans-serif;
};

export const facebookPreviewDescriptionStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: "#65676b",
  // font-family: "DM Sans",sans-serif;
};

export const twitterPriviewThumbStyle = {
  maxHeight: 350,
  fontSize: 0,
  overflow: "hidden",
};

export const twitterPreviewtitleStyle = {
  fontSize: 16,
  fontWeight: 700,
  color: "#29294f",
  // font-family: "DM Sans",sans-serif;
};

export const twitterPreviewDescriptionStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: "#65676b",
  // font-family: "DM Sans",sans-serif;
};
export const twitterPreviewLinkStyle = {
  color: "#65676b",
  fontSize: 14,
  fontWeight: 400,
};

export const imageFit = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

export const tabItemsStyle = {
  padding: "1rem 1rem",
};

export const costingStyle = {
  fontSize: 20,
  fontWeight: 600,
  color: "var(--p-color-text-brand)",
  marginTop: 5,
  marginBottom: "auto",
};

export const costingByTimeStyle = {
  fontSize: 14,
  fontWeight: 500,
};

export const prevPriceStyle = {
  fontSize: 14,
  fontWeight: 500,
  color: "var(--p-color-text-secondary)",
  textDecoration: "line-through",
  marginBottom: 10,
};

export const hintStyle = {
  fontSize: 12,
  fontWeight: 500,
  color: "var(--p-color-text-secondary)",
  marginBottom: 10,
};
