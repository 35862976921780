import { Icon, Link, Tooltip } from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
// import { useState } from "react";

const LearnMoreButton = ({ title, onClick = () => {}, external = true, url = undefined }) => {
  // const [showHint, setShowHint] = useState(false);

  return (
    <Tooltip content={title}>
      <Link
        onClick={onClick}
        target={external && "_blank"}
        url={url}
      >
        <Icon
          source={InfoIcon}
          tone="base"
        />
      </Link>
    </Tooltip>
    // <span
    //   onMouseEnter={() => setShowHint(true)}
    //   onMouseLeave={() => setShowHint(false)}
    //   style={{
    //     display: "flex",
    //     border: "1px solid",
    //     borderColor: showHint ? "var(--p-color-border)" : "transparent",
    //     padding: "var(--p-space-025) var(--p-space-100)",
    //     borderRadius: "var(--p-border-radius-400)",
    //   }}
    //   className="learn--more--btn"
    // >
    //   <Link
    //     onClick={onClick}
    //     target={external && "_blank"}
    //     url={url}
    //   >
    //     <InlineStack
    //       gap="025"
    //       blockAlign="center"
    //     >
    //       <Icon
    //         source={CircleInformationMajor}
    //         tone="base"
    //       />
    //       {showHint && (
    //         <Text
    //           tone="subdued"
    //           variant="bodySm"
    //         >
    //           {title}
    //         </Text>
    //       )}
    //     </InlineStack>
    //   </Link>
    // </span>
  );
};

export default LearnMoreButton;
