import React from "react";
import SkeletonLoader from "./SkeletonLoader.jsx";

const SubscriptionLoader = ({ gridSize }) => {
  return (
    <div className="ss-row">
      {Array.from({ length: gridSize }).map((_, index) => (
        <div
          className={`ss-col-lg-${gridSize} ss-col-sm-6`}
          key={index}
        >
          <div className="ss__package">
            <div className="package__header">
              <SkeletonLoader style={{ height: 30, width: 180, marginBottom: 10 }} />
              <SkeletonLoader style={{ height: 20, width: 100, marginBottom: 25 }} />
              <SkeletonLoader style={{ height: 54, width: 160, borderRadius: 12 }} />
            </div>

            <ul className="package__feature">
              {Array.from({ length: 13 }).map((_, index) => (
                <div key={index}>
                  <SkeletonLoader
                    style={{
                      height: 10,
                      width: `${Math.floor(Math.random() * (90 - 20 + 1)) + 20}%`,
                      marginBottom: 20,
                    }}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionLoader;
