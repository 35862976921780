import { BlockStack, Button, Card, Icon, InlineStack, Text } from "@shopify/polaris";
import { ArrowRightIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { dashboardCardContents } from "../../config";
import { useEffect, useState } from "react";

const StayTunedCard = () => {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Card background="bg-surface-hover">
      <BlockStack gap="400">
        <BlockStack gap="200">
          <Text
            as="h3"
            variant="headingLg"
          >
            {t("Stay tuned with StoreSEO")}
          </Text>
          <Text as="p">
            {t("Need more assistance? Check out our Tutorial and documentation or knock our support anytime.")}
          </Text>
        </BlockStack>

        {dashboardCardContents.map(({ key, title, content, actionText, url, Illustration }) => (
          <Card key={key}>
            <InlineStack
              wrap={windowWidth < 499 ? true : false}
              gap="200"
              align="space-between"
              blockAlign="center"
            >
              <InlineStack
                gap="200"
                blockAlign="center"
                wrap={windowWidth < 499 ? true : false}
              >
                <div style={{ flexShrink: 0 }}>
                  <Illustration />
                </div>
                <BlockStack gap="100">
                  <Text
                    as="h4"
                    variant="headingSm"
                  >
                    {t(title)}
                  </Text>
                  <Text
                    as="p"
                    tone="subdued"
                  >
                    {t(content)}
                  </Text>
                </BlockStack>
              </InlineStack>

              <div className="custom__button">
                <Button
                  url={url}
                  target="_blank"
                >
                  <Icon source={ArrowRightIcon} />
                </Button>
              </div>
            </InlineStack>
          </Card>
        ))}
      </BlockStack>
    </Card>
  );
};

export default StayTunedCard;
