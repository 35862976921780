import { InlineStack, RadioButton } from "@shopify/polaris";
import React from "react";

export default function RadioGroup({ selected, onChange, gap = "400", items = [], disabled = false }) {
  return (
    <InlineStack gap={gap}>
      {items.length &&
        items.map((item, key) => (
          <RadioComponent
            item={item}
            selected={selected}
            key={key}
            disabled={disabled}
            onChange={onChange}
          />
        ))}
    </InlineStack>
  );
}

const RadioComponent = ({ item, selected, disabled = false, onChange = () => {} }) => {
  const randomId = () => (Math.random() + 1).toString(36).substring(7);
  return (
    <RadioButton
      label={item.label}
      checked={item.value == selected}
      id={randomId()}
      name={randomId()}
      onChange={(e, f) => onChange(item, e, f)}
      disabled={disabled}
    />
  );
};
