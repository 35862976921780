import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { rootFlexStyle } from "../styles/common";
import DummyPageSkeleton from "../components/loader/DummyPageSkeleton";
import Footer from "../components/common/Footer";
import DummyProductFix from "../components/loader/DummyProductFix.jsx";
import OnboardSkeleton from "../components/loader/OnboardSkeleton.jsx";
import DashboardLoader from "@/components/loader/DashboardLoader";
import { ImageOptimizerLoader } from "@/components/loader/ImageOptimizerLoader";
import ReportLoader from "@/components/loader/ReportLoader";
import HtmlSitemapLoader from "@/components/loader/HtmlSitemapLoader";
import SubscriptionLoader from "@/components/loader/SubscriptionLoader";

export default function DefaultLayout({ children }) {
  const { pathname } = useLocation();

  return (
    <div style={rootFlexStyle}>
      {/*<Header />*/}
      <div style={{ flex: 1 }}>
        <Suspense
          fallback={
            pathname.match(/(?:(?:products)|(?:pages)|(?:articles))\/[0-9].*/gim) ? (
              <DummyProductFix />
            ) : pathname.match(/image-optimizer/gim) ? (
              <ImageOptimizerLoader />
            ) : pathname.match(/reports/gim) ? (
              <ReportLoader />
            ) : pathname.match(/html-sitemap/gim) ? (
              <HtmlSitemapLoader />
            ) : pathname.match(/subscription/gim) ? (
              <SubscriptionLoader />
            ) : pathname.match(/onboard/gim) ? (
              <OnboardSkeleton />
            ) : pathname === "/" ? (
              <DashboardLoader />
            ) : (
              <DummyPageSkeleton />
            )
          }
        >
          <Outlet />
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}
