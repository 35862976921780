import { useSelector } from "react-redux";
import { menus } from "../config";
import { useEffect, useState } from "react";

export const useMenuItems = () => {
  const user = useSelector((state) => state.user);
  const onboardStep = useSelector((state) => state.onboardStep);
  const [menuItems, setMenuItems] = useState(() => menus);

  useEffect(() => {
    if (!user?.isSubscribed) {
      setMenuItems(menus.filter((m) => m.destination === "/subscription"));
      return;
    }

    if (onboardStep !== null) {
      const onboardLinks = [{ label: "Onboard", destination: "/onboard" }];
      // if (onboardStep === 7) onboardLinks.push({ label: "AI Content Optimizer", destination: "/credit-bundles" });

      setMenuItems(onboardLinks);
      return;
    }

    setMenuItems(menus);
  }, [user, onboardStep]);

  return menuItems;
};
