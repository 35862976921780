import React from "react";

const SkeletonLoader = ({ style, lineHeight }) => {
  return (
    <div
      className="skeleton-wrapper"
      style={{ lineHeight: `${lineHeight}px` }}
    >
      <div
        className="skeleton-box"
        style={style}
      >
        {/*<span className="skeleton-loading"></span>*/}
      </div>
    </div>
  );
};

export default SkeletonLoader;
