import toastMessages from "storeseo-enums/toastMessages";
import { processAndShowErrorNotification, setErrorsFromResponse, showNotification } from "../../utility/helpers";
import { useApiClient } from "../useApiClient";
import { useTranslation } from "react-i18next";

export const useProductApi = () => {
  const { t } = useTranslation();
  const apiClient = useApiClient();

  const toggleNoIndexStatus = async (productId) => {
    try {
      const { message, noIndexStatus } = await apiClient(`/products/${productId}/no-index`, {
        method: "PUT",
      });

      showNotification({
        type: "success",
        message: t(message),
      });

      return { noIndexStatus };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error toggling no-index status: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const toggleNoFollowStatus = async (productId) => {
    try {
      const { message, noFollowStatus } = await apiClient(`/products/${productId}/no-follow`, {
        method: "PUT",
      });

      showNotification({
        type: "success",
        message: t(message),
      });

      return { noFollowStatus };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error toggling no-follow status: ", err);
      throw new Error("Something went wrong!");
    }
  };

  const toggleSitemapStatus = async (productId, currentStatus) => {
    try {
      const data = {
        sitemap: {
          id: productId,
          status: currentStatus,
        },
      };

      const { sitemapStatus, message } = await apiClient(`/sitemaps/product`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      showNotification({
        type: "success",
        message: t(message),
      });

      return { sitemapStatus };
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  const updateCanonicalUrl = async (productId, canonicalUrl) => {
    try {
      const { message } = await apiClient(`products/${productId}/canonical-url`, {
        method: "PUT",
        body: JSON.stringify({ canonicalUrl }),
      });

      showNotification({
        type: "success",
        message: t(message),
      });

      return { updateSuccessful: true, message };
    } catch (err) {
      // processAndShowErrorNotification(err);
      console.log("Error updating canonical url: ", err);
      return { updateSuccessful: false, message: t("Something went wrong.") };
    }
  };
  const syncProductFromShopify = async (productId) => {
    try {
      const { message } = await apiClient(`/products/sync/${productId}`, {
        method: "POST",
        body: JSON.stringify({ sync: true }),
      });
      showNotification({
        type: "success",
        message: t(message),
      });
      return true;
    } catch (err) {
      console.log("err in single product sync: ", err);
      throw new Error("Product sync failed.");
    }
  };
  const syncProductsFromShopify = async () => {
    try {
      const { message } = await apiClient("/products/sync", {
        method: "POST",
        body: JSON.stringify({ sync: true }),
      });

      showNotification({ type: "success", message: t(message) });
      return true;
    } catch (err) {
      console.log("err in product sync: ", err);
      // processAndShowErrorNotification(err);
      throw new Error("Product sync start failed.");
    }
  };

  const uploadSocialMediaPreviewImage = async (id, file, imageFor) => {
    try {
      const formData = new FormData();
      formData.append(imageFor, file, file?.name);

      const { facebookImagePreviewURL, twitterImagePreviewURL } = await apiClient(
        `/products/${id}/social-media-images`,
        {
          method: "PUT",
          headers: {},
          body: formData,
        }
      );

      showNotification({ type: "success", message: t(toastMessages.success.PREVIEW_IMAGE_UPLOADED) });

      return { facebookImagePreviewURL, twitterImagePreviewURL };
    } catch (err) {
      console.log("Error uploading social media preview image", err);
      return { updateSuccessful: false };
    }
  };

  /**
   *
   * @param {{ [key]: string }} query
   * @returns {Promise<{products: any[], pagination: object, productCount: number}>}
   */
  const getPaginatedProductsList = async (query) =>
    apiClient("/products", {
      query,
    });

  const getProductWithOptimizationData = async (id, languageCode) =>
    apiClient(`/products/${id}${languageCode ? `?languageCode=${languageCode}` : ""}`);

  const calculateOptimizationData = async (id, data) => {
    try {
      const resData = await apiClient(`/products/${id}/optimization`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      return resData;
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  const updateProduct = async (id, formData, setFormErrors = () => {}) => {
    try {
      const { message, product } = await apiClient(`/products/${id}`, {
        method: "PUT",
        body: JSON.stringify(formData),
      });

      showNotification({ type: "success", message: t(message) });
      return product;
    } catch (err) {
      console.log("err: ", err);
      setErrorsFromResponse(err, setFormErrors);
      // processAndShowErrorNotification(err, setFormErrors);
      throw new Error("API call error!");
    }
  };

  const updateImgAltTexts = async (id, images) => {
    try {
      const { message, product } = await apiClient(`/products/${id}/update-alt-tags`, {
        method: "PUT",
        body: JSON.stringify({ images }),
      });

      showNotification({ type: "success", message: t(message) });
      return product;
    } catch (err) {
      // processAndShowErrorNotification(err);
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @param {{id:number, image: Object}} params
   * @returns
   */
  const optimizeImgAltTexts = async ({ id, image }) => {
    try {
      const { message, product } = await apiClient(`/products/${id}/optimize-alt-tags`, {
        method: "PUT",
        body: JSON.stringify({ image }),
      });

      showNotification({ type: "success", message: t(message) });
      return product;
    } catch (err) {
      throw new Error("Something went wrong!");
    }
  };

  /**
   *
   * @param {{images: {productId:string, imageId: string}[]}} props
   * @returns
   */
  const undoOptimizedImgAltTexts = async ({ images }) => {
    try {
      const result = await apiClient(`/products/restore-optimized-alt-tags`, {
        method: "PUT",
        body: JSON.stringify({ images }),
      });

      showNotification({ type: "success", message: t(result?.message) });
      return result;
    } catch (err) {
      throw new Error("Something went wrong!");
    }
  };

  const getPaginatedSitemapList = async (query) =>
    apiClient("/sitemaps/product", {
      query,
    });

  /**
   *
   * @param {{resourceList: {id: string}[], settings: any}} props
   * @returns
   */
  const generateBulkAiContent = async ({ resourceList, settings }) => {
    try {
      const data = await apiClient("/ai/generate-ai-content/queue", {
        method: "POST",
        body: JSON.stringify({
          resourceList,
          settings,
        }),
      });
      showNotification({ message: t(data?.message) });
      return data;
    } catch (err) {
      throw err;
    }
  };

  /**
   *
   * @param {{resourceList: {id: string}[]}} props
   * @returns
   */
  const restoreBulkAiContent = async ({ resourceList }) => {
    try {
      const result = await apiClient(`/ai/restore-ai-content`, {
        method: "POST",
        body: JSON.stringify({ resourceList }),
      });

      showNotification({ type: "success", message: t(result?.message) });
      return result;
    } catch (err) {
      throw new Error("Something went wrong!");
    }
  };

  return {
    syncProductFromShopify,
    syncProductsFromShopify,
    getPaginatedProductsList,
    getProductWithOptimizationData,
    // getNoIndexStatus,
    toggleNoIndexStatus,
    // getNoFollowStatus,
    toggleNoFollowStatus,
    updateCanonicalUrl,
    calculateOptimizationData,
    updateProduct,
    updateImgAltTexts,
    uploadSocialMediaPreviewImage,
    getPaginatedSitemapList,
    toggleSitemapStatus,
    optimizeImgAltTexts,
    undoOptimizedImgAltTexts,
    generateBulkAiContent,
    restoreBulkAiContent,
  };
};
