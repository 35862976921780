import { useApiClient } from "../useApiClient.js";

export const useUserApi = () => {
  const apiClient = useApiClient();

  const getAuthUserData = async () => {
    try {
      return await apiClient("/auth");
    } catch (err) {
      console.error("getAuthUserData", err);
    }
  };

  const getBetterDocsAppInstallStatus = async () => {
    try {
      return await apiClient("/docs/installStatus");
    } catch (error) {
      console.error("Error: ", error);
      throw new Error("Failed to get active install status");
    }
  };

  return {
    getAuthUserData,
    getBetterDocsAppInstallStatus,
  };
};
