import React from "react";

const Logo = ({ width = 201.894, height = 57.875 }) => (
  <svg
    width="100"
    height="34"
    viewBox="0 0 100 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_44407_102018"
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="94"
      height="28"
    >
      <path
        d="M96.6795 3.42969H3.32007V30.5721H96.6795V3.42969Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_44407_102018)">
      <path
        d="M38.8228 23.2729C37.67 23.2729 36.6819 22.9723 36.1549 22.7217V21.1349C36.9125 21.4857 37.7524 21.6862 38.444 21.6862C39.6298 21.6862 40.1732 21.185 40.1732 20.0993C40.1732 18.9635 39.745 18.6462 38.3782 17.8612C36.9125 17.0093 36.0232 16.3746 36.0232 14.5206C36.0232 12.416 37.3077 11.2969 39.7121 11.2969C40.5355 11.2969 41.3754 11.4305 42.1 11.6643L41.8859 13.1342C41.3919 12.9671 40.7167 12.867 40.0909 12.867C39.2345 12.867 38.3617 13.034 38.3617 14.2366C38.3617 15.005 38.6746 15.2722 40.0415 16.1241C41.6719 17.1262 42.5117 17.7442 42.5117 19.7486C42.5282 22.1371 41.326 23.2729 38.8228 23.2729Z"
        fill="#AFAFAF"
      />
      <path
        d="M47.2023 23.2723C45.5061 23.2723 44.7485 22.4872 44.7485 20.7334V15.8728H43.6287V14.3528H44.8144L44.9626 12.2148H46.9388V14.3528H48.8492L48.7832 15.8728H46.9388V20.6499C46.9388 21.4683 47.1365 21.9026 48.1575 21.9026C48.3551 21.9026 48.5527 21.8692 48.7174 21.8024L48.6515 23.0217C48.2892 23.1888 47.7622 23.2723 47.2023 23.2723Z"
        fill="#AFAFAF"
      />
      <path
        d="M53.6599 23.2744C50.5803 23.2744 50.1521 21.5206 50.1521 19.7835V17.6121C50.1521 15.2736 51.3049 14.1211 53.6599 14.1211C56.0313 14.1211 57.1841 15.2569 57.1841 17.6121V19.7835C57.1841 22.2388 56.1302 23.2744 53.6599 23.2744ZM53.6599 15.5074C52.4742 15.5074 52.3424 16.4261 52.3424 17.1109V20.3346C52.3424 21.0028 52.4742 21.9047 53.6599 21.9047C54.8456 21.9047 54.9774 21.0028 54.9774 20.3346V17.1109C54.9938 16.0252 54.5657 15.5074 53.6599 15.5074Z"
        fill="#AFAFAF"
      />
      <path
        d="M58.7341 23.0718V14.3528H60.7433V15.9062C61.1715 14.6534 62.0772 14.1523 63.1642 14.1523C63.1971 14.1523 63.23 14.1523 63.2465 14.1523V16.1734C63.2136 16.1734 63.1971 16.1734 63.1642 16.1734C62.3242 16.1734 61.4515 16.4908 60.9574 16.9919L60.9245 17.0253V23.0884H58.7341V23.0718Z"
        fill="#AFAFAF"
      />
      <path
        d="M68.2853 23.2722C65.8479 23.2722 64.4646 22.0028 64.4646 19.798V17.5932C64.4646 15.3048 65.6009 14.1523 67.8242 14.1523C70.0806 14.1523 71.1341 15.1712 71.1341 17.3761V19.1132H66.6219V19.8815C66.6219 21.1509 67.4621 21.8691 68.9111 21.8691C69.5041 21.8691 70.1298 21.7522 70.8874 21.4683L70.8212 22.8379C70.2282 23.1052 69.224 23.2722 68.2853 23.2722ZM67.8406 15.4385C66.7701 15.4385 66.6219 16.2235 66.6219 17.0086V18.0609H69.0429V17.0086C69.0429 16.2235 68.8947 15.4385 67.8406 15.4385Z"
        fill="#AFAFAF"
      />
      <path
        d="M75.3422 23.2729C74.1898 23.2729 73.2019 22.9723 72.6746 22.7217V21.1349C73.4322 21.4857 74.2724 21.6862 74.9638 21.6862C76.1497 21.6862 76.6928 21.185 76.6928 20.0993C76.6928 18.9635 76.2645 18.6462 74.8982 17.8612C73.4322 17.0093 72.5427 16.3746 72.5427 14.5206C72.5427 12.416 73.8277 11.2969 76.2317 11.2969C77.0555 11.2969 77.8951 11.4305 78.6199 11.6643L78.4054 13.1342C77.9115 12.9671 77.2366 12.867 76.6108 12.867C75.7542 12.867 74.8811 13.034 74.8811 14.2366C74.8811 15.005 75.1946 15.2722 76.561 16.1241C78.1916 17.1262 79.0312 17.7442 79.0312 19.7486C79.0482 22.1371 77.8295 23.2729 75.3422 23.2729Z"
        fill="#AFAFAF"
      />
      <path
        d="M80.6365 23.0719V11.5469H86.8783V13.0669H82.9749V16.5076H86.2355V18.0276H82.9749V21.5186H87.0259V23.0719H80.6365Z"
        fill="#AFAFAF"
      />
      <path
        d="M92.4622 23.2719C89.7446 23.2719 88.2458 21.7018 88.2458 18.8623V15.7221C88.2458 12.916 89.7774 11.3125 92.4622 11.3125C95.2289 11.3125 96.6778 12.8325 96.6778 15.7221V18.8957C96.6778 21.6851 95.1298 23.2719 92.4622 23.2719ZM92.4622 12.8826C91.2106 12.8826 90.6833 13.6008 90.6833 15.2878V19.4135C90.6833 20.9836 91.227 21.7018 92.4622 21.7018C94.043 21.7018 94.2404 20.566 94.2404 19.4636V15.2878C94.2404 13.6008 93.7137 12.8826 92.4622 12.8826Z"
        fill="#AFAFAF"
      />
      <path
        d="M31.8226 4.98307C31.7073 4.46528 31.3779 4.0143 30.9332 3.73034C30.6204 3.52991 30.258 3.42969 29.8958 3.42969C29.2041 3.42969 28.5618 3.78045 28.1995 4.38176L17.4621 22.3041C16.8857 23.2563 17.1822 24.4923 18.1209 25.0602C18.4337 25.2606 18.796 25.3608 19.1584 25.3608C19.85 25.3608 20.4923 25.0101 20.8546 24.4088L31.6085 6.51976C31.872 6.05207 31.9543 5.51757 31.8226 4.98307Z"
        fill="#AFAFAF"
      />
      <path
        d="M20.6274 8.33854C20.5121 7.82074 20.1828 7.36976 19.7381 7.08581C19.4252 6.88537 19.0629 6.78516 18.7006 6.78516C18.0089 6.78516 17.3667 7.13592 17.0043 7.73722L8.70428 21.3669C8.12789 22.319 8.42433 23.555 9.36302 24.1229C9.67593 24.3234 10.0382 24.4235 10.4005 24.4235C11.0922 24.4235 11.7344 24.0728 12.0968 23.4715L20.3969 9.84181C20.6768 9.39083 20.7591 8.85633 20.6274 8.33854Z"
        fill="#AFAFAF"
      />
      <path
        d="M11.2862 9.59244C11.1709 9.07465 10.8415 8.62367 10.3969 8.33971C10.084 8.13927 9.72166 8.03906 9.35939 8.03906C8.66772 8.03906 8.02545 8.38982 7.66315 8.99113L3.61192 15.3717C3.33196 15.8227 3.24962 16.3739 3.38136 16.8917C3.49664 17.4095 3.80954 17.8604 4.25419 18.1444C4.56709 18.3448 4.92939 18.4451 5.2917 18.4451C5.98337 18.4451 6.62564 18.0943 6.98794 17.493L11.0556 11.1124C11.3356 10.6614 11.418 10.1269 11.2862 9.59244Z"
        fill="#AFAFAF"
      />
      <path
        d="M9.10021 28.3024C8.96846 27.317 8.12857 26.582 7.15694 26.582C7.05813 26.582 6.97579 26.582 6.87697 26.5987C5.80653 26.7491 5.04899 27.7679 5.21367 28.8703C5.34542 29.8558 6.1853 30.5907 7.15694 30.5907C7.25574 30.5907 7.33809 30.5907 7.4369 30.5741C8.49087 30.4237 9.24842 29.4048 9.10021 28.3024Z"
        fill="#AFAFAF"
      />
      <path
        d="M17.8895 28.3024C17.7578 27.317 16.9179 26.582 15.9462 26.582C15.8475 26.582 15.7651 26.582 15.6663 26.5987C14.5958 26.7491 13.8383 27.7679 14.003 28.8703C14.1348 29.8558 14.9746 30.5907 15.9462 30.5907C16.0451 30.5907 16.1274 30.5907 16.2262 30.5741C16.7532 30.4905 17.2143 30.2233 17.5272 29.789C17.8401 29.3714 17.9719 28.8369 17.8895 28.3024Z"
        fill="#AFAFAF"
      />
      <path
        d="M23.8231 11.7832C23.2302 12.635 22.0939 12.9023 21.2046 12.3511C20.3153 11.7999 20.0024 10.6307 20.4635 9.69531L20.2659 10.0127L13.9255 20.4354C13.9421 20.4186 13.9421 20.4186 13.9585 20.402C13.975 20.3852 13.9915 20.3519 14.0079 20.3351C14.0244 20.3184 14.0408 20.3017 14.0573 20.285C14.4196 19.8841 14.9466 19.6503 15.5065 19.6503C16.6099 19.6503 17.5157 20.5523 17.5157 21.6881C17.5157 21.9553 17.4663 22.2226 17.3675 22.4564L21.1388 16.2095L23.8231 11.7832Z"
        fill="#E7E7E7"
      />
      <path
        d="M13.9529 20.4183C13.9693 20.4016 13.9858 20.3682 14.0023 20.3516C14.0023 20.3682 13.9693 20.385 13.9529 20.4183Z"
        fill="#E54141"
      />
    </g>
  </svg>
);

export default Logo;
