import {
  Grid,
  Page,
  BlockStack,
  Divider,
  Text,
  Card,
  Box,
  InlineStack,
  Icon,
  TextField,
  Button,
} from "@shopify/polaris";
import SkeletonLoader from "./SkeletonLoader";
import CollapsibleCard from "../common/CollapsibleCard";

import { cardBodyPaddingStyle, flexBasisStyle, collapseHeaderStyle } from "../../styles/common";

import { XIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

const basicSeoAnalysis = [
  "Focus keyword is unique",
  "Focus keyword is used in the meta title",
  "Meta description is within 120-165 characters",
  "Meta title is within 50-70 characters",
];

const detailedSeoAnalysis = [
  "Focus keyword is used in image alt text",
  "Focus keyword is used in the product title",
  "Focus keyword found in meta description",
  "Focus keyword is at the beginning of meta title",
  "Focus keyword density is 3-6 times for product description",
  "Focus keyword is used in the URL",
  "Write a product description of 50 to 300 words",
  "Add alt text to all images",
  "All Images are optimized",
];

export default function DummyProductFix() {
  const { t } = useTranslation();
  return (
    <Page
      backAction={{
        content: "Products",
      }}
      title={<SkeletonLoader style={{ height: 20, width: "40%" }}></SkeletonLoader>}
      compactTitle
      pagination={{
        hasPrevious: false,
        hasNext: false,
      }}
    >
      <Grid>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 8, xl: 8 }}>
          <BlockStack gap="400">
            <CollapsibleCard title="General SEO">
              <div style={cardBodyPaddingStyle}>
                <BlockStack gap="400">
                  <div>
                    <div style={{ marginBottom: 8 }}>{/* <Text>Product Image</Text> */}</div>
                    <SkeletonLoader style={{ height: 80, width: 80, borderRadius: 8 }} />
                  </div>
                  <TextField
                    label={t("Meta Title")}
                    showCharacterCount
                    disabled
                    maxLength={70}
                  />
                  <TextField
                    label={t("Meta Description")}
                    multiline={4}
                    autoComplete="off"
                    showCharacterCount
                    requiredIndicator
                    maxLength={165}
                    disabled
                  />
                  <TextField
                    label={t("Focus Keyword")}
                    requiredIndicator
                    autoComplete="off"
                    disabled
                    connectedRight={
                      <Button
                        variant="primary"
                        disabled
                      >
                        Keyword Analytics
                      </Button>
                    }
                  />
                  <div>
                    <div style={{ marginBottom: 8 }}>
                      <Text tone="text-inverse">{t("Keyword Suggestions")}:</Text>
                    </div>
                    <InlineStack
                      gap="200"
                      align="start"
                    >
                      <SkeletonLoader style={{ height: 20, width: 80, borderRadius: 8 }} />
                      <SkeletonLoader style={{ height: 20, width: 100, borderRadius: 8 }} />
                      <SkeletonLoader style={{ height: 20, width: 70, borderRadius: 8 }} />
                    </InlineStack>
                  </div>
                  <TextField
                    label={t("Tags")}
                    placeholder="Add Tags"
                    autoComplete="off"
                    disabled
                  />
                  <InlineStack
                    gap="200"
                    align="start"
                  >
                    <SkeletonLoader style={{ height: 20, width: 80, borderRadius: 8 }} />
                    <SkeletonLoader style={{ height: 20, width: 100, borderRadius: 8 }} />
                    <SkeletonLoader style={{ height: 20, width: 70, borderRadius: 8 }} />
                  </InlineStack>
                </BlockStack>
              </div>
            </CollapsibleCard>

            <CollapsibleCard title="Image Alt Text">
              <div style={cardBodyPaddingStyle}>
                <BlockStack gap="400">
                  <InlineStack
                    gap="400"
                    wrap={false}
                    blockAlign="center"
                  >
                    <SkeletonLoader style={{ height: 80, width: 80, borderRadius: 8 }} />
                    <div style={flexBasisStyle}>
                      <TextField
                        label={t("Alt text")}
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </InlineStack>
                </BlockStack>
              </div>
            </CollapsibleCard>
          </BlockStack>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 4, xl: 4 }}>
          <BlockStack gap="400">
            <Card padding="0">
              <div
                className="collapse-header"
                style={collapseHeaderStyle}
              >
                <Text
                  as="h6"
                  variant="headingSm"
                >
                  {t("SEO Score")}
                </Text>
              </div>
              <Divider />
              <div style={{ ...cardBodyPaddingStyle, height: 232 }}>
                <SkeletonLoader style={{ height: 10, width: "100%" }}></SkeletonLoader>
                <SkeletonLoader style={{ height: 10, width: "100%" }}></SkeletonLoader>
                <SkeletonLoader style={{ height: 10, width: "90%" }}></SkeletonLoader>
                <SkeletonLoader style={{ height: 10, width: "60%" }}></SkeletonLoader>
              </div>
            </Card>

            {/* Basic SEO analysis */}
            <AnalysisItemList
              items={basicSeoAnalysis}
              title="Basic SEO Analysis"
              badge={{
                badgeTitle: `5 ${t("Issues")}`,
                badgeStatus: "attention",
              }}
            />

            {/* Detailed SEO analysis */}
            <AnalysisItemList
              items={detailedSeoAnalysis}
              title="Detailed SEO Analysis"
              badge={{
                badgeTitle: `5 ${t("Issues")}`,
                badgeStatus: "attention",
              }}
            />
          </BlockStack>
        </Grid.Cell>
      </Grid>
    </Page>
  );
}

const AnalysisItemList = ({ title, items, badge: { badgeTitle, badgeStatus } }) => {
  const { t } = useTranslation();
  return (
    <CollapsibleCard
      title={title}
      badgeText={badgeTitle}
      badgeStatus={badgeStatus}
    >
      <div className="seo-analytics-list">
        <BlockStack>
          {items.map((item, index) => (
            <Fragment key={`analysisitem-${index}`}>
              <AnalysisItem title={t(item)} />
              {index !== items.length - 1 && <Divider />}
            </Fragment>
          ))}
        </BlockStack>
      </div>
    </CollapsibleCard>
  );
};

const AnalysisItem = ({ title }) => {
  return (
    <Box padding="200">
      <InlineStack
        gap="200"
        wrap={false}
      >
        <span>
          <Icon
            source={XIcon}
            tone={"critical"}
          />
        </span>
        <Text as="p">{title}.</Text>
      </InlineStack>
    </Box>
  );
};
