export const colors = {
  success: "var(--p-color-bg-fill-success)",
  warning: "var(--p-color-bg-fill-warning)",
  critical: "var(--p-color-bg-fill-critical)",
};

export function useScoreColor(score, color = undefined) {
  if (color && colors.hasOwnProperty(color)) return colors[color];

  if (score >= 75) return colors.success;
  else if (score >= 50) return colors.warning;

  return colors.critical;
}
