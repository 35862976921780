import { useMemo } from "react";
import { useQuery } from "react-query";
import { ONE_MINUTE } from "../utility/helpers";
import { useAuthenticatedFetch } from "./useAuthenticatedFetch";

/**
 * A hook for querying your custom app data.
 * @desc A thin wrapper around useAuthenticatedFetch and react-query's useQuery.
 *
 * @param {{ url?: string, queryKey?: import("react-query").QueryKey | null, queryFn?: import("react-query").QueryFunction | null, fetchInit?: RequestInit, reactQueryOptions?: import("react-query").UseQueryOptions}} options - The options for your query. Accepts upto 5 keys:
 *
 * 1. url: The URL to query. E.g: /api/widgets/1`
 * 2. queryKey: the query key that will be used by react-query. If empty the **url** value will default as the query key
 * 2. queryFn: the query function that would load data & return a promise. If no queryFn provided, default **fetch** function will be used.
 * 3. fetchInit: The init options for fetch.  See: https://developer.mozilla.org/en-US/docs/Web/API/fetch#parameters
 * 4. reactQueryOptions: The options for `useQuery`. See: https://react-query.tanstack.com/reference/useQuery
 *
 * @returns Return value of useQuery.  See: https://react-query.tanstack.com/reference/useQuery.
 */
export const useAppQuery = ({ url, queryKey = null, queryFn = null, fetchInit = {}, reactQueryOptions = {} }) => {
  const authenticatedFetch = useAuthenticatedFetch();
  const fetch = useMemo(() => {
    return async () => {
      const response = await authenticatedFetch(url, fetchInit);
      return response.json();
    };
  }, [url, queryKey, JSON.stringify(fetchInit)]);

  return useQuery(queryKey || url, queryFn || fetch, {
    refetchOnWindowFocus: false,
    staleTime: 5 * ONE_MINUTE,
    refetchInterval: false,
    // refetchInterval: 5 * ONE_MINUTE,
    ...reactQueryOptions,
  });
};
