import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineError,
  InlineStack,
  Link,
  Select,
  Text,
  Banner,
} from "@shopify/polaris";
import { AlertCircleIcon, AlertTriangleIcon, InfoIcon } from "@shopify/polaris-icons";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import RadioGroup from "../../components/common/RadioGroup";
// import SettingToggleCard from "../../components/common/SettingToggleCard";
import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { BANNER_UPGRADE_IMAGE_OPTIMIZER } from "storeseo-enums/cacheKeys";
import { IMAGE_OPTIMIZER_OPTIONS } from "../../config";
import { useAppQuery, useShopApi } from "../../hooks";
import { useBanner } from "../../hooks/useBanner";
import useUserAddon from "../../hooks/useUserAddon";
import { setHiddenBanner } from "../../store/features/HiddenBanner";
import { getCheckoutPath } from "../../utility/helpers";
import queryKeys from "../../utility/queryKeys";
import HintText from "../common/HintText";
import useConfirmation from "@/hooks/useConfirmation";

export const IMAGE_OPTIMIZER_INITIAL_SETTINGS = {
  compressionType: "none",
  format: "none",
  resize: "none",
  autoOptimization: false,
};

const ImageOptimizerSettingsCard = ({ isOnboarding = false, onComplete = () => {}, onBack = () => {} }) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState(IMAGE_OPTIMIZER_INITIAL_SETTINGS);
  const shopApi = useShopApi();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { renderConfirmation, showConfirmation, hideConfirmation } = useConfirmation();

  const hiddenBanner = useSelector((state) => state.hiddenBanner);

  const [noneSelected, setNoneSelected] = useState(true);

  const checkoutPath = getCheckoutPath(user);
  const { hasImageOptimizer, isFreeImageOptimizer } = useUserAddon();

  const { Banner: ImageOptimizerSettingsWarningBanner, showBanner: showImageOptimizerSettingsWarningBanner } =
    useBanner({
      title: t("Warning"),
      message: t(
        "To use the Image Optimizer feature, you must select the compression methods from here. Currently, all are selected as 'None'. {{ONBOARD_MESSAGE}}",
        {
          ONBOARD_MESSAGE: isOnboarding
            ? t("You can also configure this from the Image Optimizer settings afterwards")
            : "",
        }
      ),
      icon: AlertTriangleIcon,
      tone: "warning",
      noMargin: true,
    });

  const { Banner: ImageOptimizerUpgradeBanner, showBanner: showImageOptimizerUpgradeBanner } = useBanner({
    title: (
      <Text>
        <Trans
          t={t}
          i18nKey="bannerForImageFreePlanUser"
        >
          It seems you are on a FREE plan for the Image Optimization Add-On.
          <Link url={checkoutPath}>Upgrade to a higher plan</Link> to access Auto Image Optimization.
        </Trans>
      </Text>
    ),
    noMargin: true,
    tone: "info",
    icon: InfoIcon,
    onDismiss: () => {
      dispatch(setHiddenBanner({ bannerKey: BANNER_UPGRADE_IMAGE_OPTIMIZER, status: false }));
      shopApi.hideBanner(BANNER_UPGRADE_IMAGE_OPTIMIZER, 7);
    },
  });

  const checkNoneSelected = (settings) => {
    if (!isEmpty(settings)) {
      const newSetting = { ...settings };
      delete newSetting.autoOptimization;
      return Object.values(newSetting).filter((val) => val !== "none").length === 0;
    }
    return true;
  };

  const { isFetching } = useAppQuery({
    queryKey: [queryKeys.IMAGE_OPTIMIZE_SETTINGS],
    queryFn: () => shopApi.getImageOptimizerSetting(),
    reactQueryOptions: {
      staleTime: 0,
      onSuccess(data) {
        setSettings({ ...IMAGE_OPTIMIZER_INITIAL_SETTINGS, ...data });
        showImageOptimizerSettingsWarningBanner(hasImageOptimizer && checkNoneSelected(data));
      },
    },
  });

  const { mutate: updateSetting, isLoading: isUpdating } = useMutation({
    mutationFn: () => shopApi.updateImageOptimizerSetting(settings),
    onSuccess: (data) => {
      const { setting } = data;
      queryClient.setQueryData([queryKeys.IMAGE_OPTIMIZE_SETTINGS], setting);
      onComplete(data);
    },
  });

  const [errors, setErrors] = useState({
    autoOptimization: "",
  });

  const { mutate: toggleAutoOptimization, isLoading: isToggling } = useMutation({
    mutationFn: () => shopApi.toggleAutoImageOptimization(!settings.autoOptimization),
    onSuccess: (data) => {
      setSettings({ ...settings, autoOptimization: !settings.autoOptimization });
      hideConfirmation();
      queryClient.invalidateQueries(queryKeys.IMAGE_OPTIMIZE_SETTINGS);
    },
  });

  const checkAndToggleAutoOptimization = () => {
    if (noneSelected) {
      setErrors({
        ...errors,
        autoOptimization: "You need to setup optimization settings before enableing auto optimization.",
      });
      hideConfirmation();
      return;
    } else {
      setErrors({
        ...errors,
        autoOptimization: "",
      });
    }

    toggleAutoOptimization();
  };

  useEffect(() => {
    setNoneSelected(checkNoneSelected(settings));
  }, [settings]);

  useEffect(() => {
    if (isFreeImageOptimizer && hiddenBanner[BANNER_UPGRADE_IMAGE_OPTIMIZER]) showImageOptimizerUpgradeBanner(true);
  }, [user]);

  const submitSetting = () => {
    // if (noneSelected) {
    //   showBanner(noneSelected);
    //   return;
    // }
    updateSetting();
  };

  return (
    <BlockStack gap="400">
      {!isOnboarding && <ImageOptimizerSettingsWarningBanner />}

      <BlockStack gap="400">
        <Card>
          <BlockStack gap="200">
            <InlineStack align="space-between">
              <BlockStack gap="150">
                <InlineStack
                  align="start"
                  blockAlign="center"
                  gap="150"
                >
                  <Text variant="headingMd">{t("Auto Image Optimization")}</Text>
                  <Badge
                    tone={
                      isFreeImageOptimizer || !hasImageOptimizer
                        ? ""
                        : settings.autoOptimization
                        ? "success"
                        : "warning"
                    }
                  >
                    {settings.autoOptimization && hasImageOptimizer && !isFreeImageOptimizer ? t("on") : t("off")}
                  </Badge>
                </InlineStack>

                <Text>{t("Images will automatically start optimizing when you upload them to the Shopify Store")}</Text>

                {errors?.autoOptimization && (
                  <InlineError
                    message={t(errors?.autoOptimization)}
                    fieldID="autoOptimization"
                  />
                )}
              </BlockStack>

              <Box>
                <Button
                  onClick={showConfirmation}
                  loading={isToggling}
                  disabled={!hasImageOptimizer || isFreeImageOptimizer}
                >
                  {t(`Turn ${settings.autoOptimization ? "off" : "on"}`)}
                </Button>
              </Box>
            </InlineStack>

            <ImageOptimizerUpgradeBanner />
            {settings.autoOptimization && (
              <Banner
                icon={AlertCircleIcon}
                tone="warning"
              >
                <Text as="p">
                  {t("Enabling this feature will automatically use the available limit on your Image Optimizer plan")}
                </Text>
              </Banner>
            )}
          </BlockStack>
        </Card>
        {/* Auto image optimization settings toggle confirmation modal */}
        {renderConfirmation({
          content: t("Are you sure you want to save the changes?"),
          primaryActionText: settings.autoOptimization ? t("Disable") : t("Enable"),
          primaryActionIsDestructive: settings.autoOptimization,
          primaryAction: checkAndToggleAutoOptimization,
          onClose: hideConfirmation,
          loading: isToggling,
        })}

        <Card>
          <BlockStack gap="400">
            <BlockStack gap="300">
              <BlockStack gap="050">
                <Text
                  as="h3"
                  variant="headingMd"
                >
                  {t("Image Compression Settings")}
                </Text>
                <Text
                  as="p"
                  tone="subdued"
                >
                  {t("Choose your default image compression settings")}
                </Text>
              </BlockStack>
              <BlockStack gap="050">
                <Text as="p">{t("Compression Types")}</Text>
                <RadioGroup
                  items={IMAGE_OPTIMIZER_OPTIONS.COMPRESSION_TYPES}
                  selected={settings.compressionType}
                  onChange={(data) => setSettings({ ...settings, compressionType: data.value })}
                  disabled={!hasImageOptimizer}
                ></RadioGroup>
                <HintText
                  content={t(
                    IMAGE_OPTIMIZER_OPTIONS.COMPRESSION_TYPES?.find((ct) => ct.value === settings.compressionType)?.hint
                  )}
                />
              </BlockStack>
            </BlockStack>

            <Bleed marginInline="400">
              <Divider />
            </Bleed>
            {/* <BlockStack gap="300">
              <BlockStack gap="050">
                <Text
                  as="h3"
                  variant="headingMd"
                  tone="disabled"
                >
                  {t("Image Format Settings")}
                </Text>
                <Text
                  as="p"
                  // tone="subdued"
                  tone="disabled"
                >
                  {t("Choose your default image format settings")}
                </Text>
              </BlockStack>
              <BlockStack gap="050">
                <Text
                  as="p"
                  tone="disabled"
                >
                  {t("Compression Formats")}
                </Text>
                <RadioGroup
                  items={IMAGE_OPTIMIZER_OPTIONS.FORMATS}
                  onChange={(data) => setSettings({ ...settings, format: data.value })}
                  selected={settings.format}
                  // disabled={!hasImageOptimizer}
                  disabled
                ></RadioGroup>
                <HintText
                  content={t(
                    IMAGE_OPTIMIZER_OPTIONS.FORMATS?.find((ct) => ct.value === settings.compressionType)?.hint
                  )}
                />
                <Banner tone="warning">
                  {t(
                    "Notice: We're currently experiencing an issue with changing the image format. Our team is actively working on it, and a fix will be available soon. Thank you for your patience!"
                  )}
                </Banner>
              </BlockStack>
            </BlockStack> */}
            {/* <Bleed marginInline="400">
              <Divider />
            </Bleed> */}

            <BlockStack gap="300">
              <BlockStack gap="050">
                <Text
                  as="h3"
                  variant="headingMd"
                >
                  {t("Advance Image Resizer")}
                </Text>
                <Text
                  as="p"
                  tone="subdued"
                >
                  {t("Choose your default image size")}
                </Text>
              </BlockStack>

              <BlockStack gap="050">
                <Select
                  label={t("Resize your image to")}
                  options={IMAGE_OPTIMIZER_OPTIONS.RESIZES}
                  onChange={(data) => setSettings({ ...settings, resize: data })}
                  value={settings.resize}
                  disabled={!hasImageOptimizer}
                />
                <HintText
                  content={t(
                    IMAGE_OPTIMIZER_OPTIONS.RESIZES?.find((ct) => ct.value === settings.compressionType)?.hint
                  )}
                />
              </BlockStack>
            </BlockStack>
          </BlockStack>
        </Card>
      </BlockStack>

      {isOnboarding && <ImageOptimizerSettingsWarningBanner />}

      <InlineStack
        align="end"
        gap="200"
      >
        {isOnboarding && <Button onClick={onBack}>{t("Back")}</Button>}
        <Button
          variant="primary"
          onClick={submitSetting}
          loading={isUpdating}
          disabled={isFetching || !hasImageOptimizer}
        >
          {t(isOnboarding ? "Next" : "Save")}
        </Button>
      </InlineStack>
    </BlockStack>
  );
};

export default ImageOptimizerSettingsCard;
