import React from "react";

const StoreSEOWalkthrough = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill="#8051FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 18.75H23.75C22.7144 18.75 21.875 19.5894 21.875 20.625V28.125H26.25C27.2856 28.125 28.125 27.2856 28.125 26.25C28.125 24.7225 28.125 22.1525 28.125 20.625C28.125 19.5894 27.2856 18.75 26.25 18.75V12.5C26.25 12.155 25.97 11.875 25.625 11.875H21.3331C21.0662 11.875 20.8263 12.0356 20.7238 12.2819C20.6219 12.5288 20.6781 12.8119 20.8669 13.0006L22.2413 14.375L20.8669 15.7494C20.6781 15.9381 20.6219 16.2213 20.7238 16.4681C20.8263 16.7144 21.0662 16.875 21.3331 16.875H25V18.75ZM20.625 20.625H18.75C18.2525 20.625 17.7756 20.8225 17.4244 21.1744C17.0725 21.5256 16.875 22.0025 16.875 22.5V28.125H20.625V20.625ZM15.625 22.5H13.75C12.7144 22.5 11.875 23.3394 11.875 24.375V26.25C11.875 27.2856 12.7144 28.125 13.75 28.125H15.625V22.5Z"
        fill="white"
      />
    </svg>
  );
};

export default StoreSEOWalkthrough;
