//@ts-check
import { Banner, Box, Text } from "@shopify/polaris";
import { useCallback, useState } from "react";

/**
 * @typedef {import("@shopify/polaris").BannerProps} BannerProps
 * @param {{show: boolean, onDismiss: () => void, title?: string, message?: string, padding: import("@shopify/polaris").BoxProps["paddingBlockEnd"], hideIcon?: boolean, tone: BannerProps["tone"], icon?:BannerProps["icon"]}} param0
 * @returns
 */
const BannerComponent = ({
  show,
  onDismiss,
  title = "",
  message = "",
  padding,
  hideIcon = false,
  tone = "warning",
  icon = undefined,
}) => {
  if (!show) return null;

  return (
    <Box paddingBlockEnd={padding}>
      <Banner
        tone={tone}
        title={title}
        onDismiss={onDismiss}
        hideIcon={hideIcon}
        icon={icon}
      >
        {message && <Text as="p">{message}</Text>}
      </Banner>
    </Box>
  );
};

/**
 * @param {{title?: string, message?: string, noMargin?: boolean, hideIcon?: boolean, tone: BannerProps["tone"], icon?:BannerProps["icon"], onDismiss?: () => void}} param0
 * @returns
 */
export const useBanner = ({
  title = "",
  message = "",
  noMargin = false,
  hideIcon = false,
  icon = undefined,
  tone = "warning",
  onDismiss = () => {},
}) => {
  const [show, setShow] = useState(false);

  const handleDismiss = () => {
    setShow(false);
    onDismiss();
  };

  const Banner = useCallback(
    () => (
      <BannerComponent
        show={show}
        onDismiss={handleDismiss}
        title={title}
        message={message}
        padding={noMargin ? "0" : "400"}
        icon={icon}
        hideIcon={hideIcon}
        tone={tone}
      />
    ),
    [show]
  );

  return {
    Banner,
    showBanner: setShow,
  };
};
