import { Box, Text } from "@shopify/polaris";

export default function SeoTag({ title, selected, onClick = () => {} }) {
  return (
    <Box
      background={selected ? "bg-fill-selected" : "bg-fill-active"}
      paddingBlockStart="100"
      paddingBlockEnd="100"
      paddingInlineStart="200"
      paddingInlineEnd="200"
      borderRadius="200"
    >
      <div
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <Text
          as="p"
          variant="bodySm"
        >
          {title}
        </Text>
      </div>
    </Box>
  );
}
