import React from "react";
import { useTranslation } from "react-i18next";

const Confirmation = ({ options, closeModal }) => {
  const { t } = useTranslation();

  return (
    <div className="ss-modal__wrap show">
      <div className="ss-modal ss-modal--lg">
        {/* <button type="button" onClick={closeModal} className="modal__times"><i className="ss-icon ss-times"/></button> */}
        <div className="modal__body">
          <div className="delete__account__dialoge">
            <h4>{t(options.title)}</h4>
            <p>{t(options.msg)}</p>
            <div className="button__group">
              <button
                type="button"
                onClick={() => {
                  options.button.cancel.onClick();
                  closeModal();
                }}
                className={`button radius-20 mb0 ${options.button.cancel.class}`}
              >
                {t(options.button.cancel.text)}
              </button>

              <button
                type="button"
                className={`button radius-20 mb0 ${options.button.confirm.class}`}
                onClick={() => {
                  options.callback();
                  closeModal();
                }}
              >
                {t(options.button.confirm.text)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
