import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Icon,
  InlineStack,
  Page,
  ProgressBar,
  Text,
} from "@shopify/polaris";
import { MenuHorizontalIcon, PhoneIcon } from "@shopify/polaris-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "./SkeletonLoader";

function OnboardSkeleton() {
  const { t } = useTranslation();
  return (
    <Page>
      <BlockStack gap="400">
        <Card>
          <BlockStack gap="400">
            <BlockStack gap="200">
              <InlineStack
                blockAlign="center"
                align="space-between"
              >
                <BlockStack gap="100">
                  <Text
                    variant="headingMd"
                    as="h3"
                  >
                    Welcome to StoreSEO
                  </Text>
                  <Text
                    as="p"
                    tone="subdued"
                  >
                    Complete the onboarding process to get started
                  </Text>
                </BlockStack>
                <InlineStack gap="200">
                  <div className="onboard-header-icon">
                    <Button
                      variant="tertiary"
                      disabled
                      icon={MenuHorizontalIcon}
                    ></Button>
                  </div>
                </InlineStack>
              </InlineStack>
              <div className="onboardin-header-progress">
                <div className="title">
                  <Text>0 out of 5 steps are completed</Text>
                </div>
                <div className="onboarding-progress">
                  <ProgressBar
                    progress={0}
                    tone="primary"
                    size="small"
                  />
                </div>
              </div>
            </BlockStack>

            <Bleed marginInline="400">
              <Divider />
            </Bleed>

            <BlockStack gap="400">
              <InlineStack blockAlign="center">
                <span className="check-circle"></span>
                <SkeletonLoader
                  style={{ width: 135, height: 6 }}
                  lineHeight={0}
                />
              </InlineStack>
              <InlineStack blockAlign="center">
                <span className="check-circle"></span>
                <SkeletonLoader
                  style={{ width: 185, height: 6 }}
                  lineHeight={0}
                />
              </InlineStack>
              <InlineStack blockAlign="center">
                <span className="check-circle"></span>
                <SkeletonLoader
                  style={{ width: 110, height: 6 }}
                  lineHeight={0}
                />
              </InlineStack>
              <InlineStack blockAlign="center">
                <span className="check-circle"></span>
                <SkeletonLoader
                  style={{ width: 200, height: 6 }}
                  lineHeight={0}
                />
              </InlineStack>
              <InlineStack blockAlign="center">
                <span className="check-circle"></span>
                <SkeletonLoader
                  style={{ width: 125, height: 6 }}
                  lineHeight={0}
                />
              </InlineStack>
            </BlockStack>
          </BlockStack>
        </Card>

        <Card padding={0}>
          <InlineStack wrap={false}>
            <SkeletonLoader
              style={{ height: 184, width: 313 }}
              lineHeight={0}
            />
            <Box padding="400">
              <BlockStack gap="200">
                <InlineStack align="space-between">
                  <Text
                    as="h4"
                    variant="headingSm"
                  >
                    Want to schedule a call?
                  </Text>
                  <Box>
                    <Button
                      variant="tertiary"
                      icon={MenuHorizontalIcon}
                      disabled
                    ></Button>
                  </Box>
                </InlineStack>
                <Text
                  as="p"
                  tone="subdued"
                >
                  We have an in-house SEO expert who can audit your store and guide you in enhancing its SEO. Would you
                  like to book a one-on-one session with them? It’s completely FREE!
                </Text>
                <Box>
                  <Button
                    icon={PhoneIcon}
                    disabled
                  >
                    Talk to SEO expert
                  </Button>
                </Box>
              </BlockStack>
            </Box>
          </InlineStack>
        </Card>
      </BlockStack>
    </Page>
  );
}

export default OnboardSkeleton;
