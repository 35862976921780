import React, { useCallback } from "react";
import { Modal, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({
  show = false,
  title = "Confirm!",
  content = "Are you sure?",
  primaryActionText = "Confirm",
  primaryAction = () => {},
  primaryActionIsDestructive = false,
  onClose = () => {},
  loading = false,
}) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose(false);
  }, []);

  return (
    <Modal
      size="small"
      open={show}
      onClose={handleClose}
      title={`${t(title)}`}
      primaryAction={{
        content: t(primaryActionText),
        onAction: primaryAction,
        variant: "primary",
        tone: primaryActionIsDestructive ? "critical" : undefined,
        loading,
      }}
      secondaryActions={[
        {
          content: t("Cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Text as={"p"}>{t(content)}</Text>
      </Modal.Section>
    </Modal>
  );
};

export default ConfirmationModal;
