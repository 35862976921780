import { BlockStack, Card, InlineStack, Text } from "@shopify/polaris";
import ImageOptimizerSettingsCard from "../image-optimizer/SettingsCard";
import StepCompleted from "./StepCompleted";
import { useTranslation } from "react-i18next";

const TITLE = "Image Optimizer";

export default function ImageOptimizer({
  completed = false,
  active = false,
  onComplete = () => {},
  onBack = () => {},
}) {
  const { t } = useTranslation();

  if (completed || !active) {
    return (
      <StepCompleted
        title={TITLE}
        completed={completed}
      />
    );
  }

  return (
    <Card background="bg-fill-secondary">
      <InlineStack blockAlign="center">
        <span className="check-circle"></span>
        <Text
          variant="headingSm"
          as="h3"
        >
          {t(TITLE)}
        </Text>
      </InlineStack>
      <div style={{ padding: "5px 0 0 30px" }}>
        <BlockStack gap="400">
          <Text as="p">{t("Optimize all your images from the store to get the best SEO output.")}</Text>

          <ImageOptimizerSettingsCard
            isOnboarding
            onBack={onBack}
            onComplete={onComplete}
          />
        </BlockStack>
      </div>
    </Card>
  );
}
