import React from 'react';

const Icon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="50" viewBox="0 0 60 50">
    <rect id="Rectangle_6" data-name="Rectangle 6" width="50" height="50" rx="4" transform="translate(5)" fill="#00cc76" opacity="0.1"/>
    <g id="dashboard" transform="translate(-1 -2.545)">
      <path id="Path_33" data-name="Path 33"
            d="M52.818,3H9.182A8.182,8.182,0,0,0,1,11.182V43.909a8.182,8.182,0,0,0,8.182,8.182H52.818A8.182,8.182,0,0,0,61,43.909V11.182A8.182,8.182,0,0,0,52.818,3Zm2.727,40.909a2.727,2.727,0,0,1-2.727,2.727H9.182a2.727,2.727,0,0,1-2.727-2.727V11.182A2.727,2.727,0,0,1,9.182,8.455H52.818a2.727,2.727,0,0,1,2.727,2.727Z"
            fill="#00cc76"/>
      <path id="Path_34" data-name="Path 34" d="M9.727,11A2.727,2.727,0,0,0,7,13.727V24.636a2.727,2.727,0,1,0,5.455,0V13.727A2.727,2.727,0,0,0,9.727,11Z" transform="translate(10.364 13.818)" fill="#00cc76"/>
      <path id="Path_35" data-name="Path 35" d="M13.727,9A2.727,2.727,0,0,0,11,11.727V28.091a2.727,2.727,0,1,0,5.455,0V11.727A2.727,2.727,0,0,0,13.727,9Z" transform="translate(17.273 10.364)" fill="#00cc76"/>
      <path id="Path_36" data-name="Path 36" d="M17.727,7A2.727,2.727,0,0,0,15,9.727V31.545a2.727,2.727,0,0,0,5.455,0V9.727A2.727,2.727,0,0,0,17.727,7Z" transform="translate(24.182 6.909)" fill="#00cc76"/>
    </g>
  </svg>
);

export default Icon2;
