import React from 'react';

const Icon4 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61.021" height="53.028" viewBox="0 0 61.021 53.028">
    <g id="Group_719" data-name="Group 719" transform="translate(-1553.5 -34.471)">
      <path id="Rectangle_6" data-name="Rectangle 6" d="M26.929,0h0A25.071,25.071,0,0,1,52,25.071v7.357A18.571,18.571,0,0,1,33.429,51H18.571A18.571,18.571,0,0,1,0,32.429v-5.5A26.929,26.929,0,0,1,26.929,0Z" transform="translate(1558 35)" fill="#0f8af9" opacity="0.1"/>
      <g id="Layer_1" transform="translate(1553 31.999)">
        <g id="Group_44" data-name="Group 44" transform="translate(1 3.003)">
          <path id="Path_39" data-name="Path 39" d="M60.784,29.307A30.006,30.006,0,0,0,1,33,29.773,29.773,0,0,0,6.368,50.118,11.675,11.675,0,0,0,15.989,55H46a11.927,11.927,0,0,0,9.907-5.276,30.128,30.128,0,0,0,4.878-20.417ZM52.589,47.488A7.935,7.935,0,0,1,46,51H15.989a7.675,7.675,0,0,1-6.34-3.17A25.991,25.991,0,0,1,13.7,13.59,25.7,25.7,0,0,1,30.966,7a27.3,27.3,0,0,1,3.114.178A26.082,26.082,0,0,1,56.811,29.781a25.774,25.774,0,0,1-4.222,17.707Z" transform="translate(-1 -3.003)" fill="#0f8af9" stroke="#0f8af9" strokeWidth="1"/>
          <path id="Path_40" data-name="Path 40" d="M26.6,12.264l-4.534,5.754a7,7,0,1,0,3.14,2.476l4.534-5.754a2,2,0,1,0-3.14-2.476ZM21.857,26.354a3,3,0,1,1-.5-4.212A3.006,3.006,0,0,1,21.857,26.354Z" transform="translate(10.503 5.498)" fill="#0f8af9" stroke="#0f8af9" strokeWidth="1"/>
        </g>
      </g>
    </g>
  </svg>

);

export default Icon4;
