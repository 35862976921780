import React from "react";

const Documentation = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        fill="#29845A"
      />
      <path
        d="M22.6016 16.2734C21.9231 16.2734 21.3711 15.7214 21.3711 15.043V11H15.0781C14.0119 11 13.1445 11.8674 13.1445 12.9336V27.0664C13.1445 28.1326 14.0119 29 15.0781 29H24.9219C25.9881 29 26.8555 28.1326 26.8555 27.0664V16.2734H22.6016ZM16.0273 23.6562H18.5839C18.8751 23.6562 19.1112 23.8924 19.1112 24.1836C19.1112 24.4748 18.8751 24.7109 18.5839 24.7109H16.0273C15.7361 24.7109 15.5 24.4748 15.5 24.1836C15.5 23.8924 15.7361 23.6562 16.0273 23.6562ZM15.5 21.3711C15.5 21.0799 15.7361 20.8438 16.0273 20.8438H23.7617C24.053 20.8438 24.2891 21.0799 24.2891 21.3711C24.2891 21.6623 24.053 21.8984 23.7617 21.8984H16.0273C15.7361 21.8984 15.5 21.6623 15.5 21.3711ZM23.7617 18.0312C24.053 18.0312 24.2891 18.2674 24.2891 18.5586C24.2891 18.8498 24.053 19.0859 23.7617 19.0859H16.0273C15.7361 19.0859 15.5 18.8498 15.5 18.5586C15.5 18.2674 15.7361 18.0312 16.0273 18.0312H23.7617Z"
        fill="white"
      />
      <path
        d="M22.4258 15.043C22.4258 15.1399 22.5046 15.2188 22.6016 15.2188H26.6204C26.5239 15.0407 26.3998 14.8771 26.2507 14.736L22.8609 11.529C22.7293 11.4046 22.5828 11.3007 22.4258 11.2188V15.043H22.4258Z"
        fill="white"
      />
    </svg>
  );
};

export default Documentation;
