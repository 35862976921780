import React, { createContext, useContext, useEffect, useState } from "react";
import socketEvents from "storeseo-enums/socketEvents";
import { showNotification } from "../utility/helpers.jsx";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { resetProductSyncInfo } from "../store/features/ProductSync.js";
import { setProductCount } from "../store/features/ProductCount.js";
import { addNewNotification, readAllNotifications, readNotification } from "../store/features/Notifications.js";
import { resetBlogSyncInfo } from "../store/features/BlogSync.js";
import { resetPageSyncInfo } from "../store/features/PageSync.js";
import { setOptimizationTaskOngoing } from "../store/features/OptimizationTask.js";
import { useTranslation } from "react-i18next";
import toastMessages from "storeseo-enums/toastMessages";
import { resetCollectionSyncInfo } from "../store/features/CollectionSync.js";
import { setCollectionCount } from "../store/features/CollectionCount.js";
import { resetDocSyncInfo } from "@/store/features/DocSync.js";
import { setDocCount } from "@/store/features/DocCount.js";

// import Pusher from "pusher-js";

// /**
//  * @returns {import("pusher-js").default}
//  */
// const pusher = () =>
//   new Pusher(process.env.FRONTEND_PUSHER_APP_KEY, {
//     cluster: process.env.FRONTEND_PUSHER_APP_CLUSTER,
//   });

const PusherContext = createContext();
/**
 * @returns {{ pusherChannel: import("pusher-js").Channel, socketId: string }}
 */
export const usePusher = () => useContext(PusherContext);

const PusherProvider = ({ children }) => {
  /**
   * @type {[import("pusher-js").default]}
   */
  const [pusherClient, setPusherClient] = useState(null);
  /**
   * @type {[import("pusher-js").Channel]}
   */
  const [pusherChannel, setPusherChannel] = useState(null);
  const [socketId, setSocketId] = useState(null);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /**
   *
   * @param {import("../../jsDocTypes").ProductSyncCompleteEventDetails} payload
   */
  const productSyncCompleteHandler = (payload) => {
    dispatch(resetProductSyncInfo());
    dispatch(setProductCount(payload.total));
    showNotification({
      message: t(toastMessages.success.PRODUCTS_SYNC_COMPLETED),
      type: "success",
    });
  };

  const docSyncCompleteHandler = (payload) => {
    dispatch(resetDocSyncInfo());
    dispatch(setDocCount(payload.total));
    showNotification({
      message: t(toastMessages.success.DOCS_SYNC_COMPLETED),
      type: "success",
    });
  };

  /**
   *
   * @param {import("../../jsDocTypes").ProductSyncCompleteEventDetails} payload
   */
  const collectionSyncCompleteHandler = (payload) => {
    dispatch(resetCollectionSyncInfo());
    dispatch(setCollectionCount(payload.total));
    showNotification({
      message: t(toastMessages.success.COLLECTIONS_SYNC_COMPLETED),
      type: "success",
    });
  };

  /**
   *
   * @param {import("../../jsDocTypes").BlogArticlesSyncUpdateEventDetails} payload
   */
  const blogSyncCompleteHandler = (payload) => {
    dispatch(resetBlogSyncInfo());
    showNotification({
      message: t(toastMessages.success.ARTICLES_SYNC_COMPLETED),
      type: "success",
    });
  };

  /**
   *
   * @param {import("../../jsDocTypes").PageSyncCompleteEventDetails} payload
   */
  const pageSyncCompleteHandler = (payload) => {
    dispatch(resetPageSyncInfo());
    showNotification({
      message: t(toastMessages.success.PAGES_SYNC_COMPLETED),
      type: "success",
    });
  };

  /**
   *
   * @param {import("../../jsDocTypes").OptimizationTaskFinishEvent} payload
   */
  const optimizationTaskFinishHandler = (payload) => {
    dispatch(setOptimizationTaskOngoing(false));
    showNotification({
      message: t(toastMessages.success.PRODUCTS_OPTIMIZATION_COMPLETED),
      type: "success",
    });
  };

  /**
   * @param {import("../../jsDocTypes").NotificationReadEventDetails} payload
   */
  const notificationReadEventHandler = (payload) => {
    dispatch(readNotification(payload.id));
  };

  /**
   * @param {import("../../jsDocTypes").AllNotificationReadEventDetails}
   */
  const allNotificationReadEventHandler = (payload) => {
    dispatch(readAllNotifications());
  };

  /**
   * @param {import("../../jsDocTypes").notificationEventPayload} payload
   */
  const newNotificationEventHandler = (payload) => {
    dispatch(addNewNotification(payload.notification));
  };

  const setupPusher = async () => {
    const { default: Pusher } = await import("pusher-js");

    const client = new Pusher(process.env.FRONTEND_PUSHER_APP_KEY, {
      cluster: process.env.FRONTEND_PUSHER_APP_CLUSTER,
    });
    setPusherClient(client);

    client.connection.bind("connected", () => setSocketId(client.connection.socket_id));

    const channel = client.subscribe(user.shop);
    setPusherChannel(channel);
  };

  useEffect(() => {
    if (!isEmpty(user) && !pusherClient) {
      setTimeout(() => setupPusher(), 3000);
      // const client = pusher();
      // setPusherClient(client);

      // client.connection.bind("connected", () => setSocketId(client.connection.socket_id));

      // const channel = client.subscribe(user.shop);
      // setPusherChannel(channel);
    }
  }, [user]);

  useEffect(() => {
    if (!pusherChannel) return;

    pusherChannel.bind(socketEvents.PRODUCT_SYNC_COMPLETE, productSyncCompleteHandler);
    pusherChannel.bind(socketEvents.BLOG_SYNC_COMPLETE, blogSyncCompleteHandler);
    pusherChannel.bind(socketEvents.PAGE_SYNC_COMPLETE, pageSyncCompleteHandler);
    pusherChannel.bind(socketEvents.PRODUCT_OPTIMIZATION_COMPLETE, optimizationTaskFinishHandler);

    pusherChannel.bind(socketEvents.NOTIFICATION_READ, notificationReadEventHandler);
    pusherChannel.bind(socketEvents.NOTIFICATION_ALL_READ, allNotificationReadEventHandler);
    pusherChannel.bind(socketEvents.NEW_NOTIFICATION, newNotificationEventHandler);
    // Collection related events
    pusherChannel.bind(socketEvents.COLLECTION_SYNC_COMPLETE, collectionSyncCompleteHandler);
    // doc related events
    pusherChannel.bind(socketEvents.DOC_SYNC_COMPLETE, docSyncCompleteHandler);
  }, [pusherChannel]);

  // useEffect(() => {
  // console.log("\n---\n socket id: ", socketId, "\n---\n");
  // }, [socketId]);

  return <PusherContext.Provider value={{ pusherChannel, socketId }}>{children}</PusherContext.Provider>;
};

export default PusherProvider;
