import { useDispatch, useSelector } from "react-redux";
import { AI_OPTIMIZER, IMAGE_OPTIMIZER } from "storeseo-enums/subscriptionAddonGroup";
import { updateAddons } from "../store/features/User";

export default function useUserAddon() {
  let { addons = [] } = useSelector((s) => s.user);

  addons ||= [];

  const dispatch = useDispatch();

  const imageOptimizer = addons?.find((a) => a.group === IMAGE_OPTIMIZER) || {};
  const hasImageOptimizer = imageOptimizer?.isActive || false;
  const isFreeImageOptimizer = imageOptimizer?.isFree || false;
  const imageOptimizerUsageCount = Number(imageOptimizer?.usageCount) || 0;
  const imageOptimizerUsageLimit = Number(imageOptimizer?.usageLimit) || 0;
  const imageOptimizerUsageRemaining = imageOptimizerUsageLimit - imageOptimizerUsageCount;
  const imageOptimizerUsageLimitExceeded = imageOptimizerUsageCount >= imageOptimizerUsageLimit;
  const imageOptimizerIsEnabled = hasImageOptimizer && !imageOptimizerUsageLimitExceeded;
  const updateImageOptimizerUsage = (count) => {
    const updatedData = addons.map((addon) => {
      let usageCount = Number(addon.usageCount);
      if (addon.group === IMAGE_OPTIMIZER) {
        usageCount = usageCount + Number(count);
      }
      return {
        ...addon,
        usageCount,
      };
    });
    dispatch(updateAddons(updatedData));
  };

  const aiOptimizer = addons?.find((a) => a.group === AI_OPTIMIZER) || {};
  const hasAiOptimizer = aiOptimizer?.isActive || false;
  const aiOptimizerUsageCount = Number(aiOptimizer?.usageCount) || 0;
  const aiOptimizerUsageLimit = Number(aiOptimizer?.usageLimit) || 0;
  const aiOptimizerUsageRemaining = aiOptimizerUsageLimit - aiOptimizerUsageCount;
  const aiOptimizerUsageLimitExceeded = hasAiOptimizer && aiOptimizerUsageCount >= aiOptimizerUsageLimit;
  const aiOptimizerIsEnabled = hasAiOptimizer && !aiOptimizerUsageLimitExceeded;
  const updateAiOptimizerUsage = (count) => {
    const updatedData = addons.map((addon) => {
      let usageCount = Number(addon.usageCount);
      if (addon.group === AI_OPTIMIZER) {
        usageCount = usageCount + Number(count);
      }
      return {
        ...addon,
        usageCount,
      };
    });
    dispatch(updateAddons(updatedData));
  };

  return {
    addons,
    imageOptimizer,
    hasImageOptimizer,
    isFreeImageOptimizer,
    imageOptimizerUsageCount,
    imageOptimizerUsageLimit,
    imageOptimizerUsageRemaining,
    imageOptimizerUsageLimitExceeded,
    imageOptimizerIsEnabled,
    updateImageOptimizerUsage,
    aiOptimizer,
    hasAiOptimizer,
    aiOptimizerUsageCount,
    aiOptimizerUsageLimit,
    aiOptimizerUsageRemaining,
    aiOptimizerUsageLimitExceeded,
    aiOptimizerIsEnabled,
    updateAiOptimizerUsage,
  };
}
