import { Box, Icon, InlineStack, Text } from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";

export default function HintText({ content }) {
  if (!content) return null;

  return (
    <InlineStack
      align="start"
      gap="050"
    >
      <Box as="span">
        <Icon
          source={InfoIcon}
          tone="subdued"
        />
      </Box>
      <Text
        as="p"
        tone="subdued"
      >
        {content}
      </Text>
    </InlineStack>
  );
}
