import { Badge, BlockStack, Button, InlineStack, Link, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { BANNER_ENABLE_AUTO_IMAGE_OPTIMIZER } from "storeseo-enums/cacheKeys";
import { useAppQuery, useShopApi } from "../../hooks";
import useUserAddon from "../../hooks/useUserAddon";
import queryKeys from "../../utility/queryKeys";
import DismissableBanner from "../common/DismissableBanner";

export default function EnableAutoImageOptimizerCard() {
  const { t } = useTranslation();
  const shopApi = useShopApi();
  const { hasImageOptimizer } = useUserAddon();

  const { data } = useAppQuery({
    queryKey: [queryKeys.IMAGE_OPTIMIZE_SETTINGS],
    queryFn: () => shopApi.getImageOptimizerSetting(),
    reactQueryOptions: {
      staleTime: 0,
    },
  });

  if (!hasImageOptimizer) return null;

  if (!data || data.autoOptimization) return null;

  return (
    <DismissableBanner
      title={
        <InlineStack
          gap="200"
          blockAlign="center"
        >
          {t("Activate optimize images automatically")} <Badge tone="attention">{t("Disabled")}</Badge>
        </InlineStack>
      }
      content={
        <BlockStack gap="200">
          <Text
            as="p"
            tone="subdued"
          >
            {t("EnableAutoImageOptimizerCardDetails")}
          </Text>

          <InlineStack
            gap="200"
            wrap={false}
            align="start"
            blockAlign="center"
          >
            <Button url="/settings/image-optimizer">{t("Enable Auto Optimize")}</Button>
            <Link url="/image-optimizer">{t("Manually Optimize")}</Link>
          </InlineStack>
        </BlockStack>
      }
      bannerKey={BANNER_ENABLE_AUTO_IMAGE_OPTIMIZER}
    />
  );
}
