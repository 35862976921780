import React from "react";
import { BlockStack, Card, Checkbox, InlineStack, RadioButton, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const SeoMigratorOption = ({ option, checked = false, onChange }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className="migrate-option">
        <BlockStack gap="200">
          <RadioButton
            label={
              <Text
                variant="headingMd"
                as="h5"
              >
                {t("Migrate SEO Data From {{title}}", { title: option.title })}
              </Text>
            }
            checked={checked}
            id={option.name}
            name="migrator"
            onChange={onChange}
            value={option.name}
          />
          <Text
            as="p"
            tone="subdued"
          >
            {t(
              "It seems you’re using {{title}} in your store. You can migrate all your SEO data from {{title}} to StoreSEO!",
              { title: option.title }
            )}
          </Text>
          <InlineStack gap="400">
            <Checkbox
              label={t("Meta Title")}
              checked={checked}
            />
            <Checkbox
              label={t("Meta Description")}
              checked={checked}
            />
            <Checkbox
              label={t("Image Alt-text")}
              checked={checked}
            />
            <Checkbox
              label={t("JSON-LD")}
              checked={checked}
            />
          </InlineStack>
        </BlockStack>
      </div>
    </Card>
  );
};

export default SeoMigratorOption;
