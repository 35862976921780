import { Icon, InlineStack, Text } from "@shopify/polaris";
import { Padding } from "../common/CustomComponent";
import { checkIcon } from "../svg/Icons";
import { useTranslation } from "react-i18next";

export default function StepCompleted({ title, completed = true }) {
  const { t } = useTranslation();

  return (
    <Padding padding="0 16px">
      <InlineStack blockAlign="center">
        {completed ? (
          <span style={{ marginRight: 10 }}>
            <Icon source={checkIcon} />
          </span>
        ) : (
          <span className="check-circle"></span>
        )}
        <Text
          variant="headingSm"
          as="h3"
        >
          {t(title)}
        </Text>
      </InlineStack>
    </Padding>
  );
}
