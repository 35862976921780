import React, { Component } from "react";
import classNames from "classnames";
import { Text } from "@shopify/polaris";

import { useScoreColor } from "../../hooks/useScoreColor.js";
import { useChartScoreVariant } from "../../hooks/useChartScoreVariant.js";

class RadialChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.background = "#F2F7FE";
  }

  componentDidMount() {
    // For initial animation
    setTimeout(() => {
      this.setState({ setStrokeLength: true });
    });
  }

  render() {
    const { setStrokeLength } = this.state;
    const { radius, score, strokeWidth, dimension, chartTitle, size, color, showPercent } = this.props;
    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * 3.14 * circleRadius;
    const strokeLength = setStrokeLength ? (circumference / 100) * score : 0;
    const bgStrokeWidth = Math.floor(strokeWidth * 1.5);

    const scoreColor = useScoreColor(score, color);
    const variant = useChartScoreVariant(size);

    return (
      <div
        className={classNames({
          "no-progress": strokeLength === 0,
        })}
        style={{ position: "relative", height: `${dimension}px` }}
      >
        <svg
          viewBox="0 0 180 180"
          width={dimension}
          height={dimension}
        >
          <circle
            stroke={this.background}
            strokeWidth={bgStrokeWidth}
            fill="none"
            cx="90"
            cy="90"
            r={circleRadius}
          />
          <circle
            stroke={`${scoreColor}`}
            strokeWidth={strokeWidth}
            strokeDasharray={`${strokeLength},${circumference}`}
            strokeLinecap="round"
            fill="none"
            cx="90"
            cy="90"
            r={circleRadius}
            style={{
              transform: "rotate(-90deg)",
              transformOrigin: "center",
              transition: "all .6s cubic-bezier(0.58, 0.16, 0.5, 1.14)",
              transitionDelay: ".3s",
            }}
          />
        </svg>
        <div
          style={{
            width: `${dimension}px`,
            height: `${dimension}px`,
            position: "absolute",
            top: 0,
            lineHeight: `${dimension}px`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            as={"span"}
            variant={variant.score}
          >
            {score}
            {showPercent && <small>%</small>}
          </Text>
          {chartTitle && (
            <Text
              as="small"
              variant={variant.title}
            >
              {chartTitle}
            </Text>
          )}
        </div>
      </div>
    );
  }
}

RadialChart.defaultProps = {
  radius: 80,
  score: 0,
  strokeWidth: 20,
  dimension: 50,
  chartTitle: "",
  size: "small", // small, medium, large
  color: null,
  showPercent: false,
};

// RadialChart.propTypes = {
//   radius: PropTypes.number,
//   score: PropTypes.number,
//   strokeWidth: PropTypes.number,
//   dimension: PropTypes.number,
//   chartTitle: PropTypes.string,
//   size: PropTypes.oneOf(["small", "medium", "large"]),
//   color: PropTypes.oneOf(["success", "warning", "critical"]),
// };

export default RadialChart;
