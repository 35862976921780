import { NOTIFICATION_API_ENDPOINT } from "../../utility/apiRoutes";
import { buildUrlSearchQueryString } from "../../utility/helpers";
import { useApiClient } from "../useApiClient";

export const useNotificationApi = () => {
  const apiClient = useApiClient();

  /**
   * Fetch list of notifications from server
   * @param {import("../../jsDocTypes").queryParam} [param0] query filters for notifications list
   * @returns {Promise<{notifications: import("../../jsDocTypes").NotificationDetails[], pagination: import("../../jsDocTypes").Pagination}>} paginated notification list
   */
  const fetchNotifications = async ({ limit = 10, page = 1, status } = {}) => {
    const params = { limit, page };
    if (status) params.status = status;

    const urlSearchString = buildUrlSearchQueryString(params);

    return apiClient(NOTIFICATION_API_ENDPOINT + urlSearchString);
  };

  return {
    fetchNotifications,
  };
};
