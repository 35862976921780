import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import socketEvents from "storeseo-enums/socketEvents";
import { useUtilityApi } from "./apiHooks";
import queryKeys from "@/utility/queryKeys";
import { readNotification } from "@/store/features/Notifications";

export const useReadNotifications = () => {
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.user);
  const utilityApi = useUtilityApi();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async (id) => {
      return await utilityApi.triggerEvent(socketEvents.NOTIFICATION_READ, {
        id,
        room: user.shop,
        shopId: user.shopId,
        socketId: user.socketId,
      });
    },

    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: [queryKeys.NOTIFICATIONS_LIST] });

      dispatch(readNotification(id));

      const previousNotifications = queryClient.getQueryData([queryKeys.NOTIFICATIONS_LIST]);

      queryClient.setQueryData([queryKeys.NOTIFICATIONS_LIST], (old) => {
        return {
          notifications: old?.notifications?.map((notification) =>
            notification?.id === id ? { ...notification, is_read: true } : notification
          ),
          pagination: old?.pagination,
        };
      });

      return { previousNotifications };
    },
    onError: (context) => {
      queryClient.setQueryData([queryKeys.NOTIFICATIONS_LIST], context.previousNotifications);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.NOTIFICATIONS_LIST] });
    },
  });
};
