import { useTranslation } from "react-i18next";
import { BANNER_GET_STARTED } from "storeseo-enums/cacheKeys";
import DismissableBanner from "../common/DismissableBanner";

const GetStartedCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      title={t("Get started")}
      content={t("Optimize your product for search engines instantly and rank them on top of the search results")}
      primaryAction={{
        content: t("Optimize your products"),
        variant: "primary",
        url: "/optimize-seo",
      }}
      bannerKey={BANNER_GET_STARTED}
    />
  );
};

export default GetStartedCard;
