import { Box, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from "@shopify/polaris";

export default function DummyPageSkeleton() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Box paddingBlockEnd="400">
            <Card>
              <SkeletonBodyText />
            </Card>
          </Box>
          <Box paddingBlockEnd="400">
            <Card>
              <SkeletonDisplayText size="small" />
              <Box paddingBlockEnd="400"></Box>
              <SkeletonBodyText />
            </Card>
          </Box>
          <Box paddingBlockEnd="400">
            <Card>
              <SkeletonDisplayText size="small" />
              <Box paddingBlockEnd="400"></Box>
              <SkeletonBodyText />
            </Card>
          </Box>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Box paddingBlockEnd="400">
            <Card>
              <SkeletonDisplayText size="small" />
              <Box paddingBlockEnd="400"></Box>
              <SkeletonBodyText lines={2} />
            </Card>
            <Box paddingBlockEnd="400"></Box>
            <Card>
              <SkeletonBodyText lines={1} />
            </Card>
          </Box>
          <Box paddingBlockEnd="400">
            <Card>
              <SkeletonDisplayText size="small" />
              <Box paddingBlockEnd="400"></Box>
              <SkeletonBodyText lines={2} />
            </Card>
            <Box paddingBlockEnd="400"></Box>
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
          </Box>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}
