import { Badge, BlockStack, Card, Grid, InlineStack, Link, SkeletonDisplayText, Text, Tooltip } from "@shopify/polaris";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppQuery, useShopApi } from "../../hooks";
import { useStoreSeo } from "../../providers/StoreSeoProvider";
import queryKeys from "../../utility/queryKeys";
import DateRangePicker from "../common/DateRangePicker.jsx";
import Icon1 from "../svg/Icon-1";
import Icon2 from "../svg/Icon-2";
import Icon3 from "../svg/Icon-3";
import Icon4 from "../svg/Icon-4";
import RadialChart from "../charts/RadialChart.jsx";
import { SCHEDULE_A_CALL_URL } from "../../config/index.js";
import { DeliveryFilledIcon } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";

let initialStats = [
  {
    key: "totalCount",
    value: 0,
    title: "Total products",
    Icon: Icon1,
    percent: 0,
    color: null,
  },
  {
    key: "overallScore",
    value: `0%`,
    title: "Overall score",
    Icon: Icon2,
    percent: 0,
    color: null,
  },
  {
    key: "seoIssues",
    value: 0,
    title: "SEO issues",
    Icon: Icon3,
    percent: 0,
    color: "critical",
  },
  {
    key: "optimizedCount",
    value: 0,
    title: "Already optimized",
    Icon: Icon4,
    percent: 0,
    color: null,
  },
];

const StatisticsRow = () => {
  const shopApi = useShopApi();
  const { doManualRefresh } = useStoreSeo();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [stats, setStats] = useState(initialStats);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    // console.log(searchParams.entries().map((sp) => sp));
    if (searchParams.get("from")) {
      setFromDate(searchParams.get("from"));
    }
    if (searchParams.get("to")) {
      setToDate(searchParams.get("to"));
    }
  }, [searchParams]);

  const { data, refetch, isFetching } = useAppQuery({
    queryKey: [queryKeys.DASHBOARD_STATS, { from: fromDate, to: toDate }],
    queryFn: () => shopApi.getDashboardStats({ from: fromDate, to: toDate }),
    reactQueryOptions: {
      staleTime: 0,
    },
  });

  const totalProducts = data?.stats?.totalCount || 0;

  if (doManualRefresh) refetch();

  useEffect(() => {
    if (!isEmpty(data?.stats)) {
      setStats(
        initialStats.map((is) => ({ ...is, value: data.stats[is.key], percent: calculatePercent(data.stats[is.key]) }))
      );
    }
  }, [data?.stats]);

  const filter = useCallback(({ since, until }) => {
    const query = {
      from: moment(since).format("YYYY-MM-DD"),
      to: moment(until).format("YYYY-MM-DD"),
    };

    setSearchParams(query);
  }, []);

  const calculatePercent = (value) => {
    value = String(value);
    if (value.includes("%")) {
      return parseInt(value.replace("%", ""));
    }
    return value > 0 ? Math.round((parseInt(value) * 100) / totalProducts) : 0;
  };

  const { isVerified, isPremium } = useSelector((state) => state.user);

  return (
    <BlockStack gap="400">
      <InlineStack
        gap="400"
        align="space-between"
        blockAlign="center"
      >
        <InlineStack gap="200">
          <Text
            variant="headingLg"
            as="h3"
          >
            {t("Store Details")}
          </Text>

          {!isPremium &&
            (isVerified ? (
              <Badge tone="success">{t("Verified")}</Badge>
            ) : (
              <Tooltip
                preferredPosition="above"
                persistOnClick
                content={t("Schedule a call with us to verify your store and double your product limit")}
              >
                <Badge tone="attention">{t("Unverified")}</Badge>
              </Tooltip>
            ))}
        </InlineStack>

        <DateRangePicker
          startDate={fromDate}
          endDate={toDate}
          onApply={filter}
          isOutline={false}
        />
      </InlineStack>
      <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}>
        {stats.map(({ key, title, value, Icon, percent, color }) => (
          <Grid.Cell key={key}>
            <Card>
              <InlineStack
                gap="200"
                align="space-between"
                blockAlign="center"
              >
                <BlockStack gap="200">
                  {isFetching ? (
                    <SkeletonDisplayText size={"medium"} />
                  ) : (
                    <Text
                      variant="headingXl"
                      as="h3"
                    >
                      {t(value)}
                    </Text>
                  )}
                  <Text
                    variant="headingSm"
                    as="h6"
                  >
                    {t(title)}
                  </Text>
                </BlockStack>

                {key !== "totalCount" && (
                  <RadialChart
                    score={percent}
                    dimension={50}
                    color={color}
                    showPercent
                  />
                )}
              </InlineStack>
            </Card>
          </Grid.Cell>
        ))}
      </Grid>
    </BlockStack>
  );
};

export default StatisticsRow;
