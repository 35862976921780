import { buildUrlSearchQueryString } from "../utility/helpers";
import { APPLICATION_JSON_HEADER } from "../utility/httpHelper";
import { useAuthenticatedFetch } from "./useAuthenticatedFetch";

/**
 * @typedef {object} ApiClientOptions
 * @property {"GET" | "POST" | "PUT" | "DELETE"} method
 * @property {{[key]: string}} headers
 * @property {{[key]: string}} query
 * @property {BodyInit} body
 */

/**
 * @callback ApiClient
 * @param {string} url api route path
 * @param {ApiClientOptions} options
 * @returns {Promise<any>}
 */

/**
 * @type {ApiClientOptions}
 */
const defaultOptions = {
  method: "GET",
  headers: APPLICATION_JSON_HEADER,
  query: {},
  body: null,
};

/**
 * @returns {ApiClient}
 */
export const useApiClient = () => {
  const fetchClient = useAuthenticatedFetch();

  return async (url, options = defaultOptions) => {
    const { method, headers, query, body } = { ...defaultOptions, ...options };
    const urlSearchString = buildUrlSearchQueryString(query);

    const response = await fetchClient(url + urlSearchString, {
      method,
      headers,
      body,
    });

    if (response.ok) return response.json();
    else throw response;
  };
};
