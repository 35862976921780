const VerifyShild = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1876 2.47107C12.2394 2.4876 12.2845 2.52012 12.3166 2.56393C12.3487 2.60774 12.366 2.6606 12.3661 2.7149V5.3392C12.3661 12.3999 7.60405 13.6407 7.05151 13.7611C7.01723 13.7686 6.98173 13.7686 6.94745 13.7611C6.39491 13.6407 1.63281 12.3999 1.63281 5.3392V2.7149C1.63295 2.6606 1.6503 2.60774 1.68238 2.56393C1.71445 2.52012 1.75959 2.4876 1.81131 2.47107C4.77021 1.5074 6.40985 0.557734 6.86648 0.2719C6.90628 0.246749 6.9524 0.233398 6.99948 0.233398C7.04656 0.233398 7.09268 0.246749 7.13248 0.2719C7.58911 0.5568 9.22875 1.5074 12.1876 2.47107Z"
        fill="#0F8060"
      />
      <path
        d="M7.11511 12.5343C7.04058 12.559 6.96005 12.559 6.88551 12.5343C5.84018 12.1924 2.80078 10.7329 2.80078 5.33898V3.62724C2.8012 3.55171 2.82497 3.47816 2.86883 3.41667C2.91269 3.35518 2.9745 3.30876 3.04578 3.28378C4.34784 2.83625 5.61274 2.28716 6.82881 1.64158C6.88151 1.61276 6.9406 1.59766 7.00066 1.59766C7.06072 1.59766 7.11982 1.61276 7.17251 1.64158C8.38866 2.28719 9.65364 2.83627 10.9558 3.28378C11.0271 3.30876 11.0889 3.35518 11.1327 3.41667C11.1766 3.47816 11.2004 3.55171 11.2008 3.62724V5.33898C11.2008 10.7306 8.16511 12.1908 7.11511 12.5343Z"
        fill="#0F8060"
      />
      <path
        d="M5.75683 8.96205L4.56147 7.72539C4.47863 7.63949 4.43273 7.52456 4.43361 7.40523C4.43448 7.28591 4.48205 7.17166 4.56614 7.08699L4.87997 6.77175C4.92274 6.72864 4.97378 6.69462 5.03003 6.67173C5.08627 6.64884 5.14657 6.63755 5.20729 6.63856C5.26801 6.63956 5.3279 6.65283 5.38336 6.67756C5.43882 6.70229 5.48871 6.73798 5.53004 6.78249L6.01187 7.29979L8.4152 4.36585C8.45281 4.31978 8.49917 4.2816 8.5516 4.25353C8.60404 4.22546 8.66151 4.20805 8.72071 4.2023C8.7799 4.19656 8.83965 4.20258 8.89651 4.22004C8.95336 4.2375 9.0062 4.26604 9.05197 4.30402L9.40197 4.59289C9.49456 4.66989 9.55298 4.78035 9.56452 4.90022C9.57605 5.02009 9.53977 5.13967 9.46357 5.23292L6.43023 8.93452C6.3899 8.98377 6.33959 9.02393 6.28263 9.05235C6.22566 9.08076 6.16332 9.0968 6.09971 9.0994C6.03611 9.102 5.97267 9.09111 5.91357 9.06744C5.85447 9.04376 5.80106 9.00785 5.75683 8.96205Z"
        fill="white"
      />
    </svg>
  );
};

export default VerifyShild;
