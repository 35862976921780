import { Badge, InlineStack } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { BANNER_VERIFY_STORE } from "storeseo-enums/cacheKeys";
import { VERIFY_URL } from "../../config";
import DismissableBanner from "../common/DismissableBanner";
import VerifyShild from "../svg/VerifyShild";

const VerifyStoreCard = () => {
  const { t } = useTranslation();

  return (
    <DismissableBanner
      title={
        <InlineStack
          gap="200"
          blockAlign="center"
        >
          <VerifyShild /> {t("Verify your store")} <Badge tone="attention">{t("Unverified")}</Badge>
        </InlineStack>
      }
      content={t("Schedule a call with us to double your product limit and remove our branding from sitemap page")}
      bannerKey={BANNER_VERIFY_STORE}
      primaryAction={{
        content: t("Schedule a call"),
        url: VERIFY_URL,
        target: "_blank",
      }}
    />
  );
};

export default VerifyStoreCard;
