export const SETTING_API_ENDPOINT = "/setting";

export const JSON_LD_API_ENDPOINT = `${SETTING_API_ENDPOINT}/jsonld`;
export const LOCAL_SEO_API_ENDPOINT = `${SETTING_API_ENDPOINT}/local-seo`;
export const GOOGLE_INTEGRATION_API_ENDPOINT = `${SETTING_API_ENDPOINT}/google-integration-info`;
export const REDIRECT_OUT_OF_STOCK_API_ENDPOINT = `${SETTING_API_ENDPOINT}/redirect-out-of-stock`;
export const THEME_MOD_TOGGLER_API_ENDPOINT = `${SETTING_API_ENDPOINT}/toggle-theme-modifications`;
export const GOOGLE_INDEXING_STATUS_API_ENDPOING = `${SETTING_API_ENDPOINT}/google-indexing`;
export const HTML_SITEMAP_API_ENDPOING = `${SETTING_API_ENDPOINT}/html-sitemap`;
export const HTMLS_SITEMAP_SETUP_INFO_ENDPOINT = `${SETTING_API_ENDPOINT}/html-sitemap/setup`;

export const SHOP_API_ENDPOINT = "/shop";
export const DASHBOARD_STATS_API_ENDPOINT = "/dashboard-stats";
export const STATISTICS_REPORT_API_ENDPOINT = "/reports";
export const PAYMENT_HISTORY_ENDPOINT = "/settings/transaction-history";
export const UTILITY_API_ENDPOINT = "/utility";
export const ADDRESS_AUTOCOMPLETE_SUGGESTIONS_API_ENDPOINT = `${UTILITY_API_ENDPOINT}/address-autocomplete-suggestions`;
export const ADDRESS_DETAILS_API_ENDPOINT = `${UTILITY_API_ENDPOINT}/address-details`;
export const KEYWORD_METRICS_API_ENDPOINT = `${UTILITY_API_ENDPOINT}/keyword-metrics`;
export const INSTALLED_SEO_APPS_API_ENDPOINT = `${UTILITY_API_ENDPOINT}/installed-apps`;
export const TRIGGER_EVENT_API_ENDPOINT = `${UTILITY_API_ENDPOINT}/trigger-event`;

export const INDUSTRY_API_ENDPOINT = "/industries";

export const NOTIFICATION_API_ENDPOINT = "/notifications";

export const ANALYTICS_API_ENDPOINT = "/analytics";
export const PRODUCTS_ANALYTICS_REPORT_ENDPOINT = `${ANALYTICS_API_ENDPOINT}/run-top-products-report-by-date`;
export const ANALYTICS_REPORT_BY_DATE_ENDPOINT = `${ANALYTICS_API_ENDPOINT}/run-report-by-date`;
export const KEYWORD_ANALYTICS_REPORT_BY_DATE_ENDPOINT = `${ANALYTICS_API_ENDPOINT}/run-keywords-report-by-date`;
export const SEARCH_CONSOLE_ANALYTICS_QUERIES_ENDPOINT = `${ANALYTICS_API_ENDPOINT}/search-console-queries-report`;

export const PARTNERS_API_ENDPOINT = "/partners";
