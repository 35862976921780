import { BlockStack, Box, Button, Card, InlineStack, Page, Text } from "@shopify/polaris";
import React from "react";
import SkeletonLoader from "./SkeletonLoader";

const HtmlSitemapLoader = () => {
  return (
    <Page
      title="HTML Sitemap"
      backAction={{ url: "#" }}
      secondaryActions={[
        {
          content: "View Sitemap",
          disabled: true,
        },
      ]}
      narrowWidth
    >
      <Card>
        <BlockStack gap="400">
          <Text>Complete the following steps to create an HTML sitemap for your store</Text>
          <InlineStack
            gap="400"
            blockAlign="center"
          >
            <SkeletonLoader
              style={{ height: 10, width: 100 }}
              lineHeight={0}
            />
            <div style={{ flex: 1 }}>
              <SkeletonLoader
                style={{ height: 8, width: "100%" }}
                lineHeight={0}
              />
            </div>
          </InlineStack>
          <Box padding="200">
            <BlockStack gap="200">
              <InlineStack
                gap="200"
                wrap={false}
              >
                <SkeletonLoader style={{ height: 20, width: 20, borderRadius: 30 }} />
                <Text
                  variant="headingSm"
                  as="h3"
                >
                  Generate HTML Sitemap
                </Text>
              </InlineStack>
              <InlineStack
                gap="200"
                wrap={false}
              >
                <SkeletonLoader style={{ height: 20, width: 20, borderRadius: 30 }} />
                <Text
                  variant="headingSm"
                  as="h3"
                >
                  How to add the HTML Sitemap link to your store
                </Text>
              </InlineStack>
              <InlineStack
                gap="200"
                wrap={false}
              >
                <SkeletonLoader
                  style={{ height: 20, width: 20, borderRadius: 30 }}
                  lineHeight={0}
                />
                <Text
                  variant="headingSm"
                  as="h3"
                >
                  Confirmation
                </Text>
              </InlineStack>
            </BlockStack>
          </Box>
        </BlockStack>
      </Card>
    </Page>
  );
};

export default HtmlSitemapLoader;
