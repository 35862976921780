import { whatsNewContents } from "@/config/index.js";
import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Icon,
  InlineStack,
  MediaCard,
  Page,
  Pagination,
  Text,
} from "@shopify/polaris";
import { CalendarIcon, MagicIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "./SkeletonLoader.jsx";

const DashboardLoader = () => {
  const { t } = useTranslation();

  const whatsNewItem = whatsNewContents?.[0];

  return (
    <Page title="">
      <BlockStack gap="400">
        <BlockStack
          gap="200"
          align="center"
        >
          <Text
            variant="heading2xl"
            as="h1"
          >
            {t("Welcome to StoreSEO")}
          </Text>
          <Text
            variant="bodyLg"
            as={"p"}
          >
            {t("Drive sales and traffic with the power of StoreSEO and improve your search engine ranking")}
          </Text>
        </BlockStack>
        <BlockStack gap="400">
          <InlineStack
            gap="400"
            align="space-between"
            blockAlign="center"
          >
            <InlineStack gap="200">
              <Text
                variant="headingLg"
                as="h3"
              >
                {t("Store Details")}
              </Text>
            </InlineStack>
            <Button
              icon={CalendarIcon}
              disabled
            >
              All time
            </Button>
          </InlineStack>
          <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}>
            <StatisticItem sTitle={t("Total products")} />
            <StatisticItem sTitle={t("Overall score")} />
            <StatisticItem sTitle={t("SEO issues")} />
            <StatisticItem sTitle={t("Already optimized")} />
          </Grid>
        </BlockStack>
        <Card>
          <BlockStack gap="300">
            <Text
              variant="headingSm"
              as="h3"
            >
              Get started
            </Text>
            <Text
              as="p"
              tone="subdued"
            >
              Optimize your product for search engines instantly and rank them on top of the search results
            </Text>
            <div>
              <Button
                variant="primary"
                disabled
              >
                Optimize your products
              </Button>
            </div>
          </BlockStack>
        </Card>
        <Card>
          <BlockStack gap="300">
            <Text
              as="p"
              tone="subdued"
            >
              To enable some advanced features (i.e., Local SEO, Google verification, Noindex/Nofollow), you need to
              enable the App Embed for StoreSEO from your Shopify settings. Go to the settings page and follow the
              instructions to enable it.
            </Text>
            <div>
              <Button disabled>App Embed Settings</Button>
            </div>
          </BlockStack>
        </Card>
        <Card>
          <Box paddingBlockEnd="400">🎉 What's New</Box>
          <Bleed marginInline="400">
            <Divider />
          </Bleed>
          <BlockStack>
            <Box
              width="70%"
              height="160px"
              paddingBlock="400"
            >
              <MediaCard
                title={t(whatsNewItem.label)}
                primaryAction={{
                  content: t(whatsNewItem.button.content),
                  url: whatsNewItem.button.url,
                }}
                description={t(whatsNewItem.content)}
                // size="small"
              >
                <div style={{ height: 160, minWidth: "30%" }}>
                  <SkeletonLoader style={{ height: 180, width: "100%" }} />
                </div>
              </MediaCard>
            </Box>

            <Bleed
              marginInline="400"
              marginBlockEnd="400"
            >
              <Box
                background="bg-surface-secondary"
                padding="100"
                paddingInlineEnd="300"
              >
                <InlineStack align="end">
                  <Pagination
                    hasPrevious
                    hasNext
                    onPrevious={() => {}}
                    onNext={() => {}}
                  />
                </InlineStack>
              </Box>
            </Bleed>
          </BlockStack>
        </Card>
        <Card padding="0">
          <InlineStack wrap={false}>
            <div style={{ height: 180, minWidth: "30%" }}>
              <SkeletonLoader style={{ height: 180, width: "100%" }} />
            </div>
            <Box padding="400">
              <BlockStack gap="200">
                <InlineStack
                  blockAlign="center"
                  gap="100"
                >
                  <Box>
                    <Icon
                      source={MagicIcon}
                      tone="magic"
                    />
                  </Box>
                  <Text
                    variant="headingSm"
                    as="h3"
                    tone="magic"
                  >
                    Introducing Our Latest AI SEO Optimization Feature 🎉
                  </Text>
                </InlineStack>
                <Text>
                  Don't know how to optimize your products for SEO? Or want to speed up your workflow? Try our AI
                  Content Optimizer to make your Meta Titles, Meta Descriptions, and Tags SEO-friendly within the blink
                  of an eye.
                </Text>
                <Box>
                  <Button disabled>Check it out</Button>
                </Box>
              </BlockStack>
            </Box>
          </InlineStack>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default DashboardLoader;

const StatisticItem = ({ sTitle }) => {
  return (
    <Grid.Cell>
      <Card>
        <InlineStack
          gap="200"
          align="space-between"
          blockAlign="center"
        >
          <BlockStack gap="200">
            <SkeletonLoader style={{ width: "40px", height: "22px" }} />
            <Text
              variant="headingSm"
              as="h6"
            >
              {sTitle}
            </Text>
          </BlockStack>

          <SkeletonLoader style={{ width: 50, height: 50, borderRadius: 50 }} />
        </InlineStack>
      </Card>
    </Grid.Cell>
  );
};
