import { BlockStack, Button, Card, Grid, InlineStack, Select, Text, TextField } from "@shopify/polaris";
import StepCompleted from "./StepCompleted";
import { t } from "i18next";
import { useAppQuery, useOnboardApi, useUtilityApi } from "../../hooks";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import queryKeys from "../../utility/queryKeys";

const TITLE = "Store Information";

export default function StoreInformation({ completed = false, active = false, onComplete = () => {} }) {
  const onboardApi = useOnboardApi();
  const utilityApi = useUtilityApi();

  const initialErrors = {
    industry: "",
  };

  const { data, isFetching } = useAppQuery({
    queryKey: "ONBOARD_STORE_INFORMATION",
    queryFn: () => onboardApi.getStoreInformationData(),
    reactQueryOptions: {
      staleTime: 0,
      refetchInterval: false,
    },
  });

  const { data: installedApps = [] } = useAppQuery({
    queryKey: queryKeys.INSTALLED_APPS_LIST,
    queryFn: utilityApi.getListOfInstalledSeoApps,
  });

  const { shop, industries = [] } = data || {};

  const [industry, setIndustry] = useState("");
  const [formErrors, setFormErrors] = useState(initialErrors);

  const { mutate: submitStoreInfo, isLoading: isUpdating } = useMutation({
    mutationFn: () => onboardApi.submitStoreInformation(Number(industry), setFormErrors),
    onSuccess: onComplete,
  });

  const { isLoading: isMigrating, mutateAsync: startProductSync } = useMutation({
    mutationFn: () => onboardApi.submitDataMigrationInfo(null),
  });

  useEffect(() => {
    if (industries.length > 0 && shop?.industry_id) {
      setIndustry(industries.find((ind) => ind.value === shop?.industry_id)?.value || null);
    } else setIndustry(industries[0]?.value ? String(industries[0]?.value) : "");
  }, [industries, shop]);

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };
    newErrors.industry = industry.length === 0 ? "Please select an industry." : "";
    setFormErrors({ ...newErrors });
    Object.values(newErrors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleSubmit = async () => {
    setFormErrors(initialErrors);
    if (!validateForm()) {
      return;
    }

    if (!installedApps.length) await startProductSync();
    submitStoreInfo();
  };

  if (completed || !active) {
    return (
      <StepCompleted
        title={TITLE}
        completed={completed}
      />
    );
  }

  return (
    <Card background="bg-fill-secondary">
      <InlineStack blockAlign="center">
        <span className="check-circle"></span>
        <Text
          variant="headingSm"
          as="h3"
        >
          {t(TITLE)}
        </Text>
      </InlineStack>
      <div style={{ padding: "5px 0 0 30px" }}>
        <BlockStack gap="400">
          <Text as="p">
            {t("Check your store URL and select your industry. Then follow the on-screen instructions.")}
          </Text>
          <Grid>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                label={t("Store URL")}
                value={shop?.url || ""}
                autoComplete="off"
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <Select
                label={t("Industry")}
                options={industries}
                onChange={(val) => {
                  setIndustry(val);
                }}
                value={Number(industry)}
                error={formErrors.industry}
              />
            </Grid.Cell>
          </Grid>
          <InlineStack align="end">
            <Button
              disabled={!industry}
              onClick={handleSubmit}
              loading={isUpdating || isMigrating}
              variant="primary"
            >
              {t("Continue")}
            </Button>
          </InlineStack>
        </BlockStack>
      </div>
    </Card>
  );
}
