const scoreSizes = {
  small: "headingXs",
  medium: "headingXl",
  large: "heading2xl",
};

const titleSizes = {
  small: "headingXs",
  medium: "headingXs",
  large: "headingSm",
};

export function useChartScoreVariant(size) {
  return {
    score: scoreSizes[size] || scoreSizes.small,
    title: titleSizes[size] || titleSizes.small,
  };
}
