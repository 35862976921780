import {
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Divider,
  InlineStack,
  RadioButton,
  Text,
} from "@shopify/polaris";
import StepCompleted from "./StepCompleted";
import { Padding } from "../common/CustomComponent";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppQuery, useOnboardApi, useShopApi } from "../../hooks";
import { useMutation, useQueryClient } from "react-query";
import SeoTagsBox from "../seo-setting/seoTagsBox";
import SeoTag from "../seo-setting/seoTag";
import { SEO_TAGS, onboardingSeoSettingOptions } from "../../config";
import ConfirmationModal from "../modals/ConfirmationModal";
import { setOptimizationTaskOngoing } from "../../store/features/OptimizationTask";

const TITLE = "SEO Settings";

export default function SeoSetting({ completed = false, active = false, onComplete = () => {}, onBack = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onboardApi = useOnboardApi();
  const shopApi = useShopApi();
  const queryClient = useQueryClient();

  const { data, refetch } = useAppQuery({
    queryKey: "ONBOARD_SEO_SETTINGS",
    queryFn: onboardApi.getSEOSettingsData,
  });

  const [selectedSeoSetting, setSelectedSeoSetting] = useState(onboardingSeoSettingOptions.KEEP_EXISTING_SETTINGS);
  const [seoTags, setSeoTags] = useState(SEO_TAGS);
  const [templateImgAltText, setTemplateImgAltText] = useState("");
  const [templatePageTitle, setTemplatePageTitle] = useState("");
  const [templateMetaDescription, setTemplateMetaDescription] = useState("");

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!data) return;

    if (SEO_TAGS.length) {
      setSeoTags(SEO_TAGS.map((tag) => ({ ...tag, selected: false })));
    }

    if (data.setting?.value) {
      setTemplatePageTitle(data.setting.value.pageTitle || "");
      setTemplateImgAltText(data.setting.value.imgAltText || "");
      setTemplateMetaDescription(data.setting.value.metaDescription || "");
    }
  }, [data]);

  const { mutate: updateSeoSetting, isLoading: isUpdatingSeoSetting } = useMutation({
    mutationFn: () =>
      onboardApi.submitSeoSetting({
        templatePageTitle,
        templateMetaDescription,
        templateImgAltText,
      }),
    onSuccess: ({ settingsUpdated }) => {
      if (settingsUpdated) {
        setShowModal(true);
      } else {
        onComplete();
      }
    },
  });

  const { mutate: startAutoOptimization, isLoading: isStartingAutoOptimization } = useMutation({
    mutationFn: () => shopApi.startAutoOptimization(),
    onSuccess: () => {
      dispatch(setOptimizationTaskOngoing(true));
      setShowModal(false);
      onComplete();
    },
  });

  const handleUpdate = () => {
    if (selectedSeoSetting === onboardingSeoSettingOptions.KEEP_EXISTING_SETTINGS) {
      return onComplete();
    }

    updateSeoSetting();
  };

  const disabledInput = selectedSeoSetting === onboardingSeoSettingOptions.KEEP_EXISTING_SETTINGS;
  const selectedCustomized = selectedSeoSetting === onboardingSeoSettingOptions.CUSTOM_SEO_TEMPLATE;

  if (completed || !active) {
    return (
      <StepCompleted
        title={TITLE}
        completed={completed}
      />
    );
  }

  return (
    <Card background="bg-fill-secondary">
      <InlineStack blockAlign="center">
        <span className="check-circle"></span>
        <Text
          variant="headingSm"
          as="h3"
        >
          {t(TITLE)}
        </Text>
      </InlineStack>
      <div style={{ padding: "5px 0 0 30px" }}>
        <BlockStack gap="400">
          <Text as="p">
            {t("Keep your existing SEO values or set a default SEO setting for all of your products.")}
          </Text>
          <Card>
            <RadioButton
              label={
                <Text
                  variant="headingSm"
                  as="h5"
                >
                  {t("Keep Existing Settings")}
                </Text>
              }
              checked={selectedSeoSetting === onboardingSeoSettingOptions.KEEP_EXISTING_SETTINGS}
              helpText={t("Keep your existing SEO values on your store")}
              id="existing"
              name="accounts"
              onChange={() => setSelectedSeoSetting(onboardingSeoSettingOptions.KEEP_EXISTING_SETTINGS)}
            />
          </Card>
          <Card>
            <BlockStack gap="400">
              <RadioButton
                label={
                  <Text
                    variant="headingSm"
                    as="h5"
                  >
                    {t("Customized")}
                  </Text>
                }
                helpText={
                  <Banner tone="warning">
                    {t("Warning: Choosing this option will override all your existing SEO values immediately")}
                  </Banner>
                }
                checked={selectedCustomized}
                id="customs"
                name="accounts"
                onChange={() => setSelectedSeoSetting(onboardingSeoSettingOptions.CUSTOM_SEO_TEMPLATE)}
              />
              {selectedCustomized && (
                <Bleed marginInline={"400"}>
                  <Divider />
                </Bleed>
              )}
            </BlockStack>

            <Collapsible
              open={selectedCustomized}
              id="customized-collapsible"
              transition={{ duration: "300ms", timingFunction: "ease-in-out" }}
              expandOnPrint
            >
              <SettingInput
                title="Choose A Template For Product Meta Title:"
                helpText="Meta Titles indicate the topic of your webpage. Use our default template or click on the tags below
                    to set your own."
                tags={seoTags}
                value={templatePageTitle}
                onChange={setTemplatePageTitle}
                charLimit={70}
                disabled={disabledInput}
              />
              <Bleed marginInline={"400"}>
                <Divider />
              </Bleed>
              <SettingInput
                title="Choose A Template For Meta Description:"
                helpText="Meta descriptions are displayed on the search results page. Use our default template or click on the
              tags below to set your own."
                tags={seoTags}
                value={templateMetaDescription}
                onChange={setTemplateMetaDescription}
                charLimit={165}
                disabled={disabledInput}
              />
              <Bleed marginInline={"400"}>
                <Divider />
              </Bleed>
              <SettingInput
                title="Choose A Template For Image Alt-Text:"
                helpText="Image alt-text helps your product images to rank on search engines for certain keywords. Use our
              default template or click on the tags below to customize it."
                tags={seoTags}
                value={templateImgAltText}
                onChange={setTemplateImgAltText}
                disabled={disabledInput}
              />
            </Collapsible>
          </Card>
          <InlineStack
            align="end"
            gap="200"
          >
            <Button onClick={onBack}>{t("Back")}</Button>
            <Button
              onClick={handleUpdate}
              loading={isUpdatingSeoSetting}
              variant="primary"
            >
              {t(selectedCustomized ? "Update" : "Next")}
            </Button>
          </InlineStack>
        </BlockStack>
      </div>

      <ConfirmationModal
        show={showModal}
        title="Shop SEO settings updated"
        content="Do you want to apply SEO Settings to all of your products?"
        primaryAction={startAutoOptimization}
        loading={isStartingAutoOptimization}
        onClose={() => {
          setShowModal(false);
          onComplete();
        }}
      />
    </Card>
  );
}

const SettingInput = ({ title, helpText, charLimit, tags, value, disabled = false, onChange = () => {} }) => {
  const [tabTwoTags, setTabTwoTags] = useState([]);
  const inputRef = useRef("");

  const { t } = useTranslation();

  useEffect(() => {
    setTabTwoTags(
      tags.map((item) => {
        if (value && value.includes(`[${item.key}]`)) {
          return { ...item, selected: true };
        }
        return { ...item, selected: false };
      })
    );
  }, [value, tags]);

  const addTagToString = (tag) => {
    if (disabled) return;

    const cursorPosition = inputRef.current.selectionStart;
    const textBeforeCursorPosition = inputRef.current.value.substring(0, cursorPosition);
    const textAfterCursorPosition = inputRef.current.value.substring(cursorPosition, inputRef.current.value.length);
    const string = `${textBeforeCursorPosition}[${tag.key}]${textAfterCursorPosition}`;
    onChange(string);
  };

  return (
    <Padding padding="20px">
      <BlockStack gap="400">
        <BlockStack gap="200">
          <Text
            variant="headingSm"
            as="h4"
          >
            {t(title)}
          </Text>
          <Text
            as="p"
            variant="bodySm"
            tone="subdued"
          >
            {t(helpText)}
          </Text>
        </BlockStack>
        <Box
          id="seo-setting-input"
          width="65%"
        >
          <div className="Polaris-Connected">
            <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
              <input
                type="text"
                name="metaDescription"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="Polaris-TextField__Input"
                ref={inputRef}
                disabled={disabled}
              />
            </div>
            <div className="Polaris-TextField__Backdrop"></div>
          </div>
          {charLimit > 0 && (
            <Box
              color="text-subdued"
              paddingBlockStart="200"
            >
              <Text variant="bodySm">{t(`Maximum ${charLimit} characters`)}</Text>
            </Box>
          )}
        </Box>
        <Box
          paddingBlockEnd="200"
          width="100%"
        >
          <SeoTagsBox>
            {tabTwoTags.map((tag) => (
              <SeoTag
                key={tag.key}
                title={tag.title}
                selected={tag.selected}
                onClick={() => addTagToString(tag)}
              />
            ))}
          </SeoTagsBox>
        </Box>
      </BlockStack>
    </Padding>
  );
};