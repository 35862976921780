import { BlockStack, Card, Grid, IndexTable, InlineStack, Page, Text } from "@shopify/polaris";
import React from "react";
import SkeletonLoader from "./SkeletonLoader";
import { useTranslation } from "react-i18next";

const ReportLoader = () => {
  const { t } = useTranslation();

  return (
    <Page
      title="Reports"
      backAction={{ url: "#" }}
    >
      <BlockStack gap="400">
        <InlineStack gap="400">
          {[1, 2, 3, 4].map((i) => (
            <SkeletonLoader
              style={{ height: 28, width: 80 }}
              lineHeight={0}
            />
          ))}
        </InlineStack>
        <BlockStack gap="300">
          <Text
            variant="headingMd"
            as="h6"
          >
            Performance Overview
          </Text>
          <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4 }}>
            <StatisticItem sTitle={t("Overall")} />
            <StatisticItem sTitle={t("Good")} />
            <StatisticItem sTitle={t("Fair")} />
            <StatisticItem sTitle={t("Poor")} />
          </Grid>
        </BlockStack>
        <BlockStack gap="300">
          <Text
            variant="headingMd"
            as="h6"
          >
            Performance Breakdown
          </Text>
          <Grid columns={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
            <StatisticPerformance sTitle={t("Total products")} />
            <StatisticPerformance sTitle={t("Total optimized products")} />
            <StatisticPerformance sTitle={t("Total unoptimized products")} />
            <StatisticPerformance sTitle={t("Total alt-text added")} />
            <StatisticPerformance sTitle={t("Total meta tags added")} />
            <StatisticPerformance sTitle={t("Total issues")} />
          </Grid>
        </BlockStack>
        <BlockStack gap="300">
          <Text
            variant="headingMd"
            as="h6"
          >
            Top scored products
          </Text>
          <TableLoader />
        </BlockStack>
      </BlockStack>
    </Page>
  );
};

export default ReportLoader;

const StatisticItem = ({ sTitle }) => {
  return (
    <Grid.Cell>
      <Card>
        <InlineStack
          gap="200"
          align="space-between"
          blockAlign="center"
        >
          <BlockStack gap="200">
            <SkeletonLoader style={{ width: "40px", height: "22px" }} />
            <Text
              variant="headingSm"
              as="h6"
            >
              {sTitle}
            </Text>
          </BlockStack>

          <SkeletonLoader style={{ width: 50, height: 50, borderRadius: 50 }} />
        </InlineStack>
      </Card>
    </Grid.Cell>
  );
};

const StatisticPerformance = ({ sTitle }) => {
  return (
    <Grid.Cell>
      <Card>
        <InlineStack
          gap="400"
          blockAlign="center"
        >
          <SkeletonLoader
            style={{ width: 60, height: 60, borderRadius: 8 }}
            lineHeight={0}
          />
          <BlockStack gap="200">
            <SkeletonLoader
              style={{ width: "40px", height: "22px" }}
              lineHeight={0}
            />
            <Text
              variant="bodyMd"
              as="p"
              tone="subdued"
            >
              {sTitle}
            </Text>
          </BlockStack>
        </InlineStack>
      </Card>
    </Grid.Cell>
  );
};

const TableLoader = () => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  return (
    <Card padding={0}>
      <IndexTable
        resourceName={resourceName}
        itemCount={5}
        selectable={false}
        headings={[{ title: "#" }, { title: "" }, { title: "Product" }, { title: "Score", alignment: "end" }]}
      >
        {[1, 2, 3, 4, 5].map((i, index) => (
          <IndexTable.Row
            key={index}
            position={index}
          >
            <IndexTable.Cell className="width-24">
              <SkeletonLoader
                style={{ width: 20, height: 10, borderRadius: 8 }}
                lineHeight={0}
              />
            </IndexTable.Cell>
            <IndexTable.Cell className="width-60">
              <SkeletonLoader
                style={{ width: 40, height: 40, borderRadius: 8 }}
                lineHeight={0}
              />
            </IndexTable.Cell>
            <IndexTable.Cell>
              <SkeletonLoader
                style={{ width: 160, height: 10 }}
                lineHeight={0}
              />
            </IndexTable.Cell>
            <IndexTable.Cell className="width-60">
              <SkeletonLoader
                style={{ width: 40, height: 40, borderRadius: 50 }}
                lineHeight={0}
              />
            </IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
    </Card>
  );
};
