import React from "react";
import { useTranslation } from "react-i18next";
import RadialChart from "../charts/RadialChart";
import { useScoreColor } from "../../hooks/useScoreColor.js";
import { BlockStack, Card, InlineStack, Text } from "@shopify/polaris";

const OptimizationRow = ({ optimization }) => {
  const { t } = useTranslation();
  const color = useScoreColor(optimization.percent);

  return (
    <>
      <Card>
        <InlineStack
          gap="200"
          wrap={false}
          align="space-between"
          blockAlign="center"
        >
          <BlockStack gap="100">
            <Text
              fontWeight="medium"
              as="p"
            >
              {t(optimization.title)}
            </Text>
            <Text
              as="p"
              variant="bodySm"
              tone="subdued"
            >
              {t(optimization.subTitle, { productCount: optimization.value })}
            </Text>
          </BlockStack>
          <div style={{ maxWidth: 40 }}>
            <RadialChart
              score={optimization.percent}
              color={color}
              dimension={50}
              size={"small"}
            />
          </div>
        </InlineStack>
      </Card>
    </>
  );
};

export default OptimizationRow;
