import { Bleed, Box, InlineStack, Pagination } from "@shopify/polaris";

const CarouselCustomPagination = ({ next, previous, goToSlide, carouselState }) => {
  const { totalItems, currentSlide } = carouselState;

  return (
    <Bleed
      marginInline="400"
      marginBlockEnd="400"
    >
      <Box
        background="bg-surface-secondary"
        padding="100"
        paddingInlineEnd="300"
      >
        <InlineStack align="end">
          <Pagination
            hasPrevious={currentSlide !== 0}
            onPrevious={previous}
            hasNext={totalItems - 1 !== currentSlide}
            onNext={next}
          />
        </InlineStack>
      </Box>
    </Bleed>
  );
};

export default CarouselCustomPagination;
